import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    paletteMode: window.localStorage.getItem('theme.paletteMode')?.toLowerCase() || 'light',
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        toggleTheme: (state) => {
            state.paletteMode = state.paletteMode === 'light' ? 'dark' : 'light';
            window.localStorage.setItem('theme.paletteMode', state.paletteMode);
        },
    },
});

export const { toggleTheme } = themeSlice.actions;

export default themeSlice.reducer;