import React, {useState, useEffect, useCallback   } from 'react';
import {
    LinearProgress,
    Stack,
    Button,
    Card,
    CardContent,
    TextField,
    InputLabel,
    Box, Paper
} from '@mui/material';

import { QuillEditor } from '../../components/common/QuillEditor';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";
import {useTheme} from '@mui/material/styles';
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import { generateEndpoints } from '../../services/api';
import toast from 'react-hot-toast';
const {
    useGetTextsQuery,
    useCreateTextMutation,
    useUpdateTextMutation,
} = generateEndpoints('text');

const InformationEdit = () => {
    const {t} = useTranslation();
    const { data: fetchedData = {}, isFetching = false } = useGetTextsQuery();
    const [updateData, { isLoading }] = useUpdateTextMutation();
    const [createData] = useCreateTextMutation();
    const [id, setId] = useState();

    const [content, setContent] = useState('');

    const handleContentChange = useCallback((value) => {
        formik.setFieldValue('privacyPolicy', value);
    }, []);

    const formik = useFormik({
        initialValues: {
            manufacturerInfo: fetchedData.manufacturerInfo || '',
            address: fetchedData.address || '',
            manufacturerContacts: fetchedData.manufacturerContacts || '',
            viber: fetchedData.viber || '',
            email: fetchedData.email || '',
            hotline: fetchedData.hotline || '',
            forgotLoginPassword: fetchedData.forgotLoginPassword || '',
            techSupportContacts: fetchedData.techSupportContacts || '',
            referenceNote: fetchedData.referenceNote || '',
            privacyPolicy: fetchedData.privacyPolicy || '',
            submit: null
        },
        validationSchema: Yup.object({
            manufacturerInfo: Yup.string(),
            address: Yup.string(),
            manufacturerContacts: Yup.string(),
            viber: Yup.string(),
            email: Yup.string().email(),
            hotline: Yup.string(),
            forgotLoginPassword: Yup.string(),
            techSupportContacts: Yup.string(),
            referenceNote: Yup.string(),
            privacyPolicy: Yup.string(),
        }),
        onSubmit: async (values, helpers) => {
            try {
                let result = null;
                if (id) {
                    result = await updateData({ id, data:{
                        data: {
                            manufacturerInfo: values.manufacturerInfo,
                            address: values.address,
                            manufacturerContacts: values.manufacturerContacts,
                            viber: values.viber,
                            email: values.email,
                            hotline: values.hotline,
                            forgotLoginPassword: values.forgotLoginPassword,
                            techSupportContacts: values.techSupportContacts,
                            referenceNote: values.referenceNote,
                            privacyPolicy: values.privacyPolicy,
                        }
                    }
                    });
                } else {
                    result = await createData({ data: values });
                }
                if (result.error){
                    toast.error(result.error.message);
                    return false;
                }
                toast.success(t('Сохранение успешно'));
            } catch (err) {
                console.error(err);
                toast.error(t('Server error'));
            }
            return false;
        },
    });

    useEffect(() => {
        if (fetchedData && fetchedData.data) {
            formik.setValues(fetchedData.data);
            setId(fetchedData.id);
        }
    }, [fetchedData]);

    return (
        <>
            {isFetching?<LinearProgress />:
                <form onSubmit={formik.handleSubmit}>
                            <Stack spacing={2} mb={2}>
                                <TextField
                                    error={!!(formik.touched.manufacturerInfo && formik.errors.manufacturerInfo)}
                                    fullWidth
                                    helperText={formik.touched.manufacturerInfo && formik.errors.manufacturerInfo}
                                    label={t('Информация о производителе')}
                                    name="manufacturerInfo"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.manufacturerInfo}
                                    multiline
                                />
                                <TextField
                                    error={!!(formik.touched.address && formik.errors.address)}
                                    fullWidth
                                    helperText={formik.touched.address && formik.errors.address}
                                    label={t('Адрес')}
                                    name="address"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.address}
                                    multiline
                                />
                                <TextField
                                    error={!!(formik.touched.manufacturerContacts && formik.errors.manufacturerContacts)}
                                    fullWidth
                                    helperText={formik.touched.manufacturerContacts && formik.errors.manufacturerContacts}
                                    label={t('Контакты производителя')}
                                    name="manufacturerContacts"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.manufacturerContacts}
                                    multiline
                                />
                                <TextField
                                    error={!!(formik.touched.viber && formik.errors.viber)}
                                    fullWidth
                                    helperText={formik.touched.viber && formik.errors.viber}
                                    label={t('Viber')}
                                    name="viber"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.viber}
                                    multiline
                                />
                                <TextField
                                    error={!!(formik.touched.email && formik.errors.email)}
                                    fullWidth
                                    helperText={formik.touched.email && formik.errors.email}
                                    label={t('Почта')}
                                    name="email"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    multiline
                                />
                                <TextField
                                    error={!!(formik.touched.hotline && formik.errors.hotline)}
                                    fullWidth
                                    helperText={formik.touched.hotline && formik.errors.hotline}
                                    label={t('Горячая линия')}
                                    name="hotline"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.hotline}
                                    multiline
                                />
                                <TextField
                                    error={!!(formik.touched.forgotLoginPassword && formik.errors.forgotLoginPassword)}
                                    fullWidth
                                    helperText={formik.touched.forgotLoginPassword && formik.errors.forgotLoginPassword}
                                    label={t('Забыли логин или пароль?')}
                                    name="forgotLoginPassword"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.forgotLoginPassword}
                                    multiline
                                />
                                <TextField
                                    error={!!(formik.touched.techSupportContacts && formik.errors.techSupportContacts)}
                                    fullWidth
                                    helperText={formik.touched.techSupportContacts && formik.errors.techSupportContacts}
                                    label={t('Контакты (тех. поддержка)')}
                                    name="techSupportContacts"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.techSupportContacts}
                                    multiline
                                />
                                <TextField
                                    error={!!(formik.touched.referenceNote && formik.errors.referenceNote)}
                                    fullWidth
                                    helperText={formik.touched.referenceNote && formik.errors.referenceNote}
                                    label={t('При обращении указать')}
                                    name="referenceNote"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.referenceNote}
                                    multiline
                                />

                                <Paper elevation={2} sx={{ p: 2 }}>
                                    <InputLabel>
                                        {t('Политика конфиденциальности')}
                                    </InputLabel>

                                    <QuillEditor
                                        onChange={handleContentChange}
                                        name="privacyPolicy"
                                        placeholder="Политика конфиденциальности"
                                        sx={{ height: 400 }}
                                        value={formik.values.privacyPolicy ?? ""}
                                    />
                                </Paper>
                            </Stack>
                        <Stack
                            direction={{
                                xs: 'column',
                                sm: 'row'
                            }}
                            flexWrap="wrap"
                            spacing={3}
                            sx={{ p: 3 }}
                        >
                            <Box sx={{ flex: 1 }}></Box>
                            <Button
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="contained"
                            >
                                {t('Сохранить')}
                            </Button>
                        </Stack>
                </form>
            }
        </>
    );
};

export default InformationEdit;
