import React from 'react';
import {Outlet} from "react-router-dom";
import { Box } from '@mui/material';
import Sidebar from '../../features/Sidebar';
import {alpha, useTheme, styled} from '@mui/material/styles';
import Topbar from "../../features/Topbar";
import { Toaster as HotToaster } from 'react-hot-toast';

const MainLayout = ({ children }) => {
    const theme = useTheme();
    /*if (!auth.user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }*/

    return (
            <Box display="flex">
                <Sidebar />
                <Box component="main" flexGrow={1} p={1}>
                    <Topbar />
                    <Outlet/>
                </Box>
                <HotToaster
                    position="bottom-right"
                    toastOptions={{
                        style: {
                            backdropFilter: 'blur(6px)',
                            background: alpha(theme.palette.neutral[900], 0.8),
                            color: theme.palette.common.white,
                            boxShadow: theme.shadows[16]
                        }
                    }}
                />
            </Box>
    );
};
export default MainLayout;