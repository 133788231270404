import React, {useEffect, useState} from 'react';
import {
    LinearProgress,
    Stack,
    Button,
    Card,
    CardContent,
    TextField,
    Box
} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import toast from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PageLayout from "../../components/layouts/PageLayout";
import { generateEndpoints } from '../../services/api';
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import {FileDropzone} from "../../components/common/FileDropzone";
import {FileList} from "../../components/common/FileList";
const { useGetNotificationByIdQuery, useUpdateNotificationMutation, useCreateNotificationMutation, useDeleteNotificationMutation  } = generateEndpoints('notification');

const NotificationEdit = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const { data: fetchedNotification = {}, isFetching = false } = id ? useGetNotificationByIdQuery(id) : { data: {} };
    const [updateNotification, { isLoading }] = useUpdateNotificationMutation();
    const [createNotification] = useCreateNotificationMutation();
    const [deleteNotification] = useDeleteNotificationMutation();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            title: fetchedNotification.title || '',
            description: fetchedNotification.description || '',
            submit: null
        },
        validationSchema: Yup.object({
            title: Yup
                .string()
                .max(255)
                .required('Заголовок обязателен для заполнения'),
            description: Yup
                .string()
                .max(1024)
        }),
        onSubmit: async (values, helpers) => {
            try {
                let result = null;
                if (id) {
                    result = await updateNotification({ id, data: { title: values.title, description: values.description ,files: fileIds  } });
                } else {
                    result = await createNotification({ data: { title: values.title, description: values.description, files: fileIds  } });
                }
                if (result.error){
                    toast.error(result.error.error);
                    return false;
                }
                toast.success('Оповещение сохранено');
                navigate('/settings');
            } catch (err) {
                console.error(err);
                toast.error('Ошибка сервера');
            }
            return false;
        }
    });

    useEffect(() => {
        if (fetchedNotification.title) {
            formik.setValues(fetchedNotification);
        }
        if (fetchedNotification.files && fetchedNotification.files.length) {
            setFetchedFiles(fetchedNotification.files)
            setFileIds(fetchedNotification.files.map(file => file.id));
        }
    }, [fetchedNotification]);

    const [fileIds, setFileIds] = useState(null);
    const [fetchedFiles, setFetchedFiles] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);

    return <PageLayout
        title={id?"Редактирование оповещения "+(fetchedNotification.title??''):"Создание оповещения"}
    >
        {isFetching?<LinearProgress />:
            <form
                onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent sx={{ p: 2 }}>
                        <Stack spacing={2} mb={2}>
                        <TextField
                            error={!!(formik.touched.title && formik.errors.title)}
                            fullWidth
                            helperText={formik.touched.title && formik.errors.title}
                            label={t('Заголовок')}
                            name="title"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required
                            value={formik.values.title}
                        />

                        <TextField
                            error={!!(formik.touched.description && formik.errors.description)}
                            fullWidth
                            helperText={formik.touched.description && formik.errors.description}
                            label={t('Описание')}
                            name="description"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required
                            value={formik.values.description}
                        />
                        <FileDropzone
                            accept={{ 'image/*': [], 'video/*': [], 'application/pdf': [] }}
                            maxFiles={1}
                            files={filesToUpload}
                            setFiles={setFilesToUpload}
                            fileIds={fileIds}
                            setFileIds={setFileIds}
                            fetchedFiles={fetchedFiles}
                            setFetchedFiles={setFetchedFiles}
                        />
                        <FileList files={fetchedFiles} setFiles={setFetchedFiles} fileIds={fileIds} setFileIds={setFileIds} />
                        </Stack>
                    </CardContent>
                    <Stack
                        direction={{
                            xs: 'column',
                            sm: 'row'
                        }}
                        flexWrap="wrap"
                        spacing={3}
                        sx={{ p: 3 }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => navigate(-1)}
                        >
                            {t('Отмена')}
                        </Button>
                        <Box sx={{ flex: 1 }}></Box>
                        {id ? <Button
                            disabled={formik.isSubmitting}
                            onClick={() => setOpenDeleteDialog(true)}
                            variant="outlined"
                            color="error"
                        >
                            {t('Удалить')}
                        </Button>: null}
                        <Button
                            disabled={formik.isSubmitting}
                            type="submit"
                            variant="contained"
                        >
                            {t('Сохранить')}
                        </Button>
                    </Stack>
                </Card>
                <ConfirmationDialog
                    open={openDeleteDialog}
                    setOpen={setOpenDeleteDialog}
                    onConfirm={async () => {
                        setOpenDeleteDialog(false);
                        const result = await deleteNotification( id );
                        if (result.error){
                            toast.error(result.error.error);
                            return false;
                        }
                        toast.success('Оповещение удалено');
                        navigate('/settings');
                    }}
                />
            </form>
        }
    </PageLayout>

        ;
};

export default NotificationEdit;