import React, {useEffect, useState} from 'react';
import {
    Box,
    LinearProgress,
    Stack,
    Button,
    SvgIcon,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useTheme} from '@mui/material/styles';
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import { generateEndpoints } from '../../services/api';
import toast from 'react-hot-toast';
const {
    useGetMaintenancesQuery,
    useCreateMaintenanceMutation,
    useUpdateMaintenanceMutation,
} = generateEndpoints('maintenance');
const MaintenanceChecks = () => {
    const theme = useTheme();

    const { data: fetchedMaintenance = {}, isFetching = false } = useGetMaintenancesQuery();

    const [createMaintenance] = useCreateMaintenanceMutation();
    const [updateMaintenance] = useUpdateMaintenanceMutation();

    const [id, setId] = useState();
    const [checks, setChecks] = useState(fetchedMaintenance.data || []);

    useEffect(() => {
        if (fetchedMaintenance.data) {
            setChecks(fetchedMaintenance.data);
            setId(fetchedMaintenance.id);
        }
    }, [fetchedMaintenance]);

    const handleSubmit = async () => {
        try {
            let result = null;

            const data = {
                data: checks,
            };

            if (id) {
                result = await updateMaintenance({ id, data });
            } else {
                result = await createMaintenance({ data });
            }

            if (result.error) {
                toast.error(result.error.error);
                return;
            }

            toast.success('Сохранение успешно');
        } catch (err) {
            console.error(err);
            toast.error('Server error');
        }
    };
    const handleAddCheck = () => {
        setChecks([...checks, {
            task: '',
            description: '',
            unr: '',
            motorHoursType: '',
            periodicity: {
                motorHours: { checked: false, value: '' },
                days: { checked: false, value: '' }
            }
        }]);
    };
    const handleMotorHoursTypeChange = (index) => (event) => {
        const newChecks = checks.map((check, i) =>
            i !== index ? check : {
                ...check,
                motorHoursType: event.target.value
            }
        );
        setChecks(newChecks);
    };

    const handlePeriodicityChange = (index, type) => (event) => {
        const newChecks = checks.map((check, i) =>
            i !== index ? check : {
                ...check,
                periodicity: {
                    ...check.periodicity,
                    [type]: {
                        checked: event.target.checked,
                        value: event.target.checked ? check.periodicity[type]?.value || '' : '',
                    },
                },
            }
        );

        setChecks(newChecks);
    };

    const handlePeriodicityValueChange = (index, type) => (event) => {
        const newChecks = [...checks];
        newChecks[index].periodicity = {
            ...newChecks[index].periodicity,
            [type]: {
                checked: newChecks[index].periodicity[type]?.checked || false,
                value: event.target.value,
            },
        };
        setChecks(newChecks);
    };

    const handleUnrChange = (index) => (event) => {
        const newChecks = checks.map((check, i) =>
            i !== index ? check : {
                ...check,
                unr: event.target.value
            }
        );
        setChecks(newChecks);
    };

    const handleTaskChange = (index) => (event) => {
        const newChecks = checks.map((check, i) =>
            i !== index ? check : {
                ...check,
                task: event.target.value
            }
        );
        setChecks(newChecks);
    };

    const handleDescriptionChange = (index) => (event) => {
        const newChecks = checks.map((check, i) =>
            i !== index ? check : {
                ...check,
                description: event.target.value
            }
        );
        setChecks(newChecks);
    };

    const handleDeleteCheck = (index) => {
        const newChecks = [...checks];
        newChecks.splice(index, 1);
        setChecks(newChecks);
    };

    return (isFetching?<LinearProgress />:
        <>
            {checks.map((check, i) => (
                <Accordion key={i}>
                    <AccordionSummary
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            '&.Mui-expanded': {
                                backgroundColor: theme.palette.background.paper,
                            },
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${i}a-content`}
                        id={`panel${i}a-header`}
                    >
                        <TextField
                            fullWidth
                            placeholder="Заголовок"
                            id={`outlined-multiline-static-${i}`}
                            multiline
                            rows={check.task?.length > 100 ? 3 : 1}
                            inputProps={{style: { resize: 'vertical' }}}
                            onClick={(e) => e.stopPropagation()}
                            defaultValue={check.task}
                            variant="standard"
                            onBlur={handleTaskChange(i)}
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    placeholder="Описание"
                                    id={`outlined-multiline-description-${i}`}
                                    multiline
                                    rows={check.description?.length > 100 ? 3 : 1}
                                    inputProps={{style: { resize: 'vertical' }}}
                                    onClick={(e) => e.stopPropagation()}
                                    defaultValue={check.description??''}
                                    variant="standard"
                                    onBlur={handleDescriptionChange(i)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checks[i].periodicity?.motorHours?.checked || false}
                                                onChange={handlePeriodicityChange(i, 'motorHours')}
                                            />
                                        }
                                        label="Каждые N моточасов"
                                    />
                                    {checks[i].periodicity?.motorHours?.checked && (
                                        <TextField
                                            placeholder={'Моточасы'}
                                            type="number"
                                            variant="standard"
                                            value={checks[i].periodicity?.motorHours?.value || ''}
                                            onChange={handlePeriodicityValueChange(i, 'motorHours')}
                                        />
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checks[i].periodicity?.days?.checked || false}
                                                onChange={handlePeriodicityChange(i, 'days')}
                                            />
                                        }
                                        label="Каждые X дней"
                                    />
                                    {checks[i].periodicity?.days?.checked && (
                                        <TextField
                                            type="number"
                                            placeholder={'Дни'}
                                            variant="standard"
                                            value={checks[i].periodicity?.days?.value || ''}
                                            onChange={handlePeriodicityValueChange(i, 'days')}
                                        />
                                    )}
                                </Stack>
                            </Grid>
                            {checks[i].periodicity?.motorHours?.checked && (
                                <Grid item  xs={12} md={6}>
                                    <FormControl
                                        fullWidth
                                        variant="standard">
                                        <InputLabel>Тип моточасов</InputLabel>
                                        <Select
                                            value={checks[i].motorHoursType}
                                            onChange={handleMotorHoursTypeChange(i)}
                                            label="Тип моточасов"
                                        >
                                            <MenuItem value={10}>Наработка бурового насоса</MenuItem>
                                            <MenuItem value={20}>Наработка ДВС</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                                <FormControl
                                    fullWidth
                                    variant="standard">
                                    <InputLabel>К каким УНР применимо</InputLabel>
                                    <Select
                                        value={checks[i].unr}
                                        onChange={handleUnrChange(i)}
                                        label="К каким УНР применимо"
                                    >
                                        <MenuItem value={1}>Все</MenuItem>
                                        <MenuItem value={2}>УНР с ДВС</MenuItem>
                                        <MenuItem value={3}>УНР с WPT</MenuItem>
                                        <MenuItem value={6}>УНР с ШПМ</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Stack
                            direction={{
                                xs: 'column',
                                sm: 'row'
                            }}
                            flexWrap="wrap"
                            spacing={3}
                            sx={{ p: 3 }}
                        >
                            <Box sx={{ flex: 1 }}></Box>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={(e) => {e.stopPropagation(); handleDeleteCheck(i);}}>
                                Удалить запись
                            </Button>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            ))}

            <Stack
                direction={{
                    xs: 'column',
                    sm: 'row'
                }}
                flexWrap="wrap"
                spacing={3}
                sx={{ p: 3 }}
            >
                <Button

                    startIcon={
                        <SvgIcon>
                            <PlusIcon />
                        </SvgIcon>
                    }
                    onClick={handleAddCheck}
                    type="button"
                    variant="contained"
                >
                    {'Добавить запись'}
                </Button>
                <Box sx={{ flex: 1 }}></Box>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    {'Сохранить'}
                </Button>
            </Stack>
        </>);
};

const MaintenanceList = () => {
    const {t} = useTranslation();

    /*const checks = [
        {
            task: "Насос буровой: заменить масло в картере. 1 замена",
            unr: 2,
            motorHoursType: 10,
            periodicity: {
                motorHours: { checked: true, value: 100 },
                days: { checked: false, value: "" }
            }
        },
        {
            task: "Насос буровой: заменить масло в картере. 2 замена",
            unr: 1,
            motorHoursType: 20,
            periodicity: {
                motorHours: { checked: false, value: "" },
                days: { checked: true, value: 30 }
            }
        },
        {
            task: "ДВС: заменить масло",
            unr: 1,
            motorHoursType: 20,
            periodicity: {
                motorHours: { checked: true, value: 200 },
                days: { checked: false, value: "" }
            }
        },
        {
            task: "ДВС: Заменить ОЖ",
            unr: 1,
            motorHoursType: 20,
            periodicity: {
                motorHours: { checked: true, value: 150 },
                days: { checked: false, value: "" }
            }
        },
        {
            task: "ДВС: Чистка и замена элемента воздушного фильтра",
            unr: 1,
            motorHoursType: 20,
            periodicity: {
                motorHours: { checked: true, value: 50 },
                days: { checked: false, value: "" }
            }
        },
        {
            task: `Насос буровой:
1 Проверить систему смазки:
- уровень и чистоту масла в картере;
- работу системы по прозрачной колбе-индикатору;
- отсутствие утечек масла из системы смазки;
2 Проверить соединение штоков между собой (узел бугельного соединения) и износ рабочей поверхности штока ползуна.
3 Проверить исправность всех предохранительных устройств (ПКВД, ПКНД, мембраны, клапан предохранительный) и датчиков 
4 Проверить систему промывки поршнего узла:
- уровень и чистоту СОЖ;
- отсутствие утечек.
- разбрызгивание СОЖ внутри втулки.
5 Проверить давление в пневмокомпенсаторах`,
            unr: 1,
            motorHoursType: 20, // Assuming 20 is for "Наработка ДВС"
            periodicity: {
                motorHours: { checked: true, value: 150 },
                days: { checked: false, value: "" }
            }
        },
        {
            task: "ДВС: Замена фильтрэлемента топливной системы",
            unr: 1,
            motorHoursType: 20,
            periodicity: {
                motorHours: { checked: true, value: 150 },
                days: { checked: false, value: "" }
            }
        }
    ];*/

    return <>
        <Box>
            <MaintenanceChecks />
        </Box>
    </>
        ;
};

export default MaintenanceList;