import React, {useEffect} from 'react';
import {Autocomplete, Box, LinearProgress, Stack, TextField} from '@mui/material';
import {SideNavItem} from './SideNavItem';
import {useTranslation} from 'react-i18next';
import {useTheme} from '@mui/material/styles';
import {useDispatch, useSelector} from "react-redux";
import {generateEndpoints} from '../../../services/api';
import {setCurrentUser, setSelectedOrganization} from "../../../store";
import {useNavigate} from "react-router-dom";

const {useGetOrganizationsQuery} = generateEndpoints('organization');


const SidebarContent = (props) => {
    const theme = useTheme();
    const {links = [], pathname, subheader = '', ...other} = props;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedOrganization = useSelector((state) => state.organization.selected);


    const {data: organizations = [], isFetching} = useGetOrganizationsQuery();

    useEffect(() => {
        if (!selectedOrganization || !selectedOrganization.id) {
            dispatch(setSelectedOrganization(organizations[0]));
        }
    }, [organizations]);

    return (
        <>
            <Stack
                component="ul"
                spacing={0.5}
                sx={{
                    listStyle: 'none',
                    m: 0,
                    p: 0
                }}
                {...other}>
                <Box sx={{
                    p: 2
                }}>
                    {isFetching ? <LinearProgress/> : <Autocomplete
                        disablePortal
                        disableClearable
                        options={organizations}
                        value={selectedOrganization && selectedOrganization.name ? selectedOrganization : organizations[0]}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            if (newValue !== null) {
                                dispatch(setSelectedOrganization(newValue));
                                navigate('/users');
                            }
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Организация"
                            sx={{
                                borderRadius: 1,
                                backgroundColor: theme.palette.background.paper,
                                color: theme.palette.text.primary
                            }}
                        />}
                    />}
                </Box>
                {props.links.map((link, index) => {

                        return (
                            <SideNavItem
                                open={true}
                                depth={link.depth}
                                icon={null}
                                key={index}
                                children={link.children}
                                title={t(link.title)}
                                path={link.path}
                            />
                        )
                    }
                )}
                <div style={{flex: 1}}></div>
                <SideNavItem
                    depth={0}
                    icon={null}
                    title={t('Настройки')}
                    path={'/settings'}
                />
            </Stack>
        </>
    )
};
export default SidebarContent;