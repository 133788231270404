import React, {useEffect, useState} from 'react';
import PageLayout from './../../components/layouts/PageLayout';
import {
    Card,
    Button,
    SvgIcon,
    Grid,
    CardContent,
    Box,
    Stack,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    LinearProgress,
    useMediaQuery
} from '@mui/material';


import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { v4 as uuidv4 } from 'uuid';
import {styled, useTheme} from '@mui/material/styles';

import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
import Star01Icon from '@untitled-ui/icons-react/build/esm/Star01';
import Trash02Icon from '@untitled-ui/icons-react/build/esm/Trash02';
import Download01Icon from '@untitled-ui/icons-react/build/esm/Download01';
import FolderPlusIcon from '@untitled-ui/icons-react/build/esm/FolderPlus';
import FolderEditIcon from '@untitled-ui/icons-react/build/esm/Edit05';
import FolderIcon from '@untitled-ui/icons-react/build/esm/Folder';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import {useTranslation} from "react-i18next";
import { FileIcon } from '../../components/common/FileIcon';
import {FileDropzone} from "../../components/common/FileDropzone";
import {FileList} from "../../components/common/FileList";
import toast from "react-hot-toast";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import {useDispatch, useSelector} from "react-redux";
import { generateEndpoints } from '../../services/api';
import {setCurrentUser} from "../../store";
const { useGetDocumentsQuery, useUpdateDocumentMutation, useCreateDocumentMutation } = generateEndpoints('document');

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    }
}));

function StyledTreeItem(props) {
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        ...other
    } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}
function Documents() {
    const {t} = useTranslation();
    const [treeData, setTreeData] = useState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const [document, setDocument] = useState(null);
    const selectedOrganization = useSelector((state) => state.organization.selected);
    const { data: documents = [], isFetching } = useGetDocumentsQuery(selectedOrganization?.id || null);
    const [updateDocument] = useUpdateDocumentMutation();
    const [createDocument] = useCreateDocumentMutation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (documents.length) {
            try {
                setTreeData(JSON.parse(documents[0]?.tree));
                setDocument(documents[0]);
            } catch (e) {}
        } else {
            setTreeData([]);
            setDocument([]);
        }
    }, [documents]);

    const handleClick = (node) => {
        setSelectedNode(node);
    }

    function countLeafNodes(node) {
        if (!node.children) {
            return node.isFolder ? 0 : 1;
        }
        return node.children.reduce((count, child) => count + countLeafNodes(child), 0);
    }
    const renderTree = (nodes) => {
          
          const count = countLeafNodes(nodes);
          const labelInfo = nodes.isFolder ? `${count}` : null;
        return (
            <StyledTreeItem key={nodes.id} labelInfo={labelInfo} nodeId={nodes.id} labelText={nodes.label} labelIcon={nodes.isFolder?FolderIcon:null}>
                {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
            </StyledTreeItem>
        )
    };
    const [folderName, setFolderName] = useState('Новая папка');
    const [editedFolder, setEditedFolder] = useState(null);
    const [showAddFolderDialog, setShowAddFolderDialog] = useState(false);
    const [showRenameFolderDialog, setShowRenameFolderDialog] = useState(false);
    const [showAddFileDialog, setShowAddFileDialog] = useState(false);

    const handleAddFolder = (nodeId) => {
        setEditedFolder(nodeId);
        setShowAddFolderDialog(true);
    };
    const handleRenameFolder = () => {
        setEditedFolder(selectedNode.id);
        setFolderName(selectedNode.label);
        setShowRenameFolderDialog(true);
    };

    const handleFolderNameChange = (event) => {
        setFolderName(event.target.value);
    };

    const handleRenameFolderDialogClose = () => {
        setShowRenameFolderDialog(false);
        setFolderName('');
    };

    const handleFolderDialogClose = () => {
        setShowAddFolderDialog(false);
        setFolderName('');
    };

    const handleFileDialogClose = () => {
        setShowAddFileDialog(false);
    };
    function handleUploadComplete(fetchedFiles) {
        if (selectedNode && fetchedFiles.length) {
            const newFiles = fetchedFiles.map((file) => ({
                ...file,
                dbId: file.id,
                label: file.filename,
                ts: file.date,
                date: new Date(file.date*1000).toLocaleString(),
                isFolder: false,
                id: uuidv4()
            }));
            let newTreeData = [...treeData];
            newFiles.forEach((file) => {
                newTreeData = updateTree(newTreeData, selectedNode.id, 'add', file);
            })
            updateAndSendTree(newTreeData);
        }
        handleFileDialogClose();
    }

    function updateTree(nodes, targetId, operation, data) {
        return nodes.map(node => {
            if (node.id === targetId) {
                switch(operation) {
                    case 'add':
                        return {...node, children: [...(node.children || []), data]};
                    case 'rename':
                        return {...node, label: data};
                    default:
                        return node;
                }
            }
            if (Array.isArray(node.children)) {
                return {...node, children: updateTree(node.children, targetId, operation, data)};
            }
            return node;
        });
    }

    function handleAddFolderSubmit() {
        if (folderName === '') {
            toast.error(t('Имя папки не может быть пустым'))
            return;
        }
        const newFolder = {
            id: uuidv4(),
            label: folderName,
            isFolder: true
        };
        let newTreeData;
        if (!editedFolder) {
            newTreeData = [...treeData, newFolder];
            updateAndSendTree(newTreeData);
        } else {
            newTreeData = updateTree(treeData, editedFolder, 'add', newFolder);
            updateAndSendTree(newTreeData);
            setExpandedNodes(prevExpanded => [...prevExpanded, editedFolder]);
        }
        setFolderName('');
        handleFolderDialogClose();
    }

    function handleRenameFolderSubmit() {
        if (selectedNode) {
            const newTreeData = updateTree(treeData, selectedNode.id, 'rename', folderName);
            updateAndSendTree(newTreeData);
        }
        selectedNode.label = folderName;
        setSelectedNode(selectedNode);
        handleRenameFolderDialogClose();
    }

    function handleDeleteFolder() {
        if (selectedNode) {
            const newTreeData = deleteFromTree(treeData, selectedNode.id);
            updateAndSendTree(newTreeData);
            setSelectedNode(null);
        }
    }

    function deleteFromTree(nodes, targetId) {
        return nodes.filter(node => node.id !== targetId).map(node => {
            if (Array.isArray(node.children)) {
                return {...node, children: deleteFromTree(node.children, targetId)};
            }
            return node;
        });
    }
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [fileIds, setFileIds] = useState(null);
    const [fetchedFiles, setFetchedFiles] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [expandedNodes, setExpandedNodes] = React.useState([]);

    function findNodeById(nodes, id) {
        for(let i = 0; i < nodes.length; i++) {
            if(nodes[i].id === id) {
                return nodes[i];
            }
            else if(Array.isArray(nodes[i].children)) {
                let found = findNodeById(nodes[i].children, id);
                if(found) {
                    return found;
                }
            }
        }
    }
    async function updateAndSendTree(newTreeData) {
        setTreeData(newTreeData);
        await sendTreeToServer(newTreeData);
    }
    async function sendTreeToServer(treeData) {
        let result = null;
        if (document && document.id) {
            result = await updateDocument({id: document.id, data: {
                tree: treeData,
                    organization_id: selectedOrganization.id
            } });
        } else {
            result = await createDocument({ data: {
                tree: treeData,
                organization_id: selectedOrganization.id
            } });
        }
        if (result.error){
            toast.error(result.error.error);
            return false;
        }
    }

    return <PageLayout
        title={"Документы"}
        actions={[
            {
                label:"Новая папка",
                clickHandler: handleAddFolder
            }
        ]}
    >
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item sm={12} lg={4}>
                    <TreeView
                        expanded={expandedNodes}
                        onNodeFocus={(event, nodeId) => {
                            handleClick(findNodeById(treeData, nodeId))
                        }}
                        onNodeToggle={(event, nodes) => setExpandedNodes(nodes)}
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{ minHeight: 240, flexGrow: 1,  overflowY: 'auto' }}
                    >
                        {treeData.map((node) => renderTree(node))}
                    </TreeView>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                    <Box
                        sx={{ minHeight: 240, flexGrow: 1,  overflowY: 'auto' }}>
                        {selectedNode && (
                            <div>
                                <Box sx={{ px: 3, py: 2 }}>
                                    {!selectedNode.isFolder && <Box
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.mode === 'dark'
                                                ? 'neutral.800'
                                                : 'neutral.50',
                                            borderColor: (theme) => theme.palette.mode === 'dark'
                                                ? 'neutral.500'
                                                : 'neutral.300',
                                            borderRadius: 1,
                                            borderStyle: 'dashed',
                                            borderWidth: 1,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            mb: 2,
                                            p: 3
                                        }}
                                    >
                                        <FileIcon type={selectedNode.type} extension={selectedNode.extension} />
                                    </Box>}
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        justifyContent="space-between"
                                        spacing={2}
                                        sx={{ mb: 2 }}
                                    >
                                        <Typography variant="h6">
                                            {selectedNode.label}
                                        </Typography>
                                    </Stack>
                                    {!selectedNode.isFolder && <Grid item sx={{ mb: 2 }}>
                                        <Typography variant="body1">{t('Тип')}: {selectedNode.extension}</Typography>
                                        <Typography variant="body1">{t('Дата')}: {selectedNode.date}</Typography>
                                        <Typography variant="body1">{t('Размер')}: {selectedNode.size}</Typography>
                                    </Grid>}
                                    <Stack
                                        direction={isMobile?"column":"row"}
                                        spacing={2}>
                                        {!selectedNode.isFolder && <Button
                                            href={selectedNode.src}
                                            target="_blank"
                                            startIcon={(
                                                <SvgIcon>
                                                    <Download01Icon />
                                                </SvgIcon>
                                            )}
                                            variant="outlined"
                                        >
                                            {t('Скачать')}
                                        </Button>}

                                        {selectedNode.isFolder && <Button
                                            onClick={() => handleAddFolder(selectedNode.id)}
                                            startIcon={(
                                                <SvgIcon>
                                                    <FolderPlusIcon />
                                                </SvgIcon>
                                            )}
                                            variant="outlined"
                                            sx={{ whiteSpace: 'nowrap' }}
                                        >
                                            {t('Добавить папку')}
                                        </Button>}
                                        {selectedNode.isFolder && <Button
                                            onClick={() => handleRenameFolder(selectedNode.id)}
                                            startIcon={(
                                                <SvgIcon>
                                                    <FolderEditIcon />
                                                </SvgIcon>
                                            )}
                                            variant="outlined"
                                        >
                                            {t('Переименовать')}
                                        </Button>}
                                        <Button
                                            onClick={() => setOpenDeleteDialog(true)}
                                            startIcon={(
                                                <SvgIcon>
                                                    <Trash02Icon />
                                                </SvgIcon>
                                            )}
                                            variant="outlined"
                                            color="error"
                                        >
                                            {t('Удалить')}
                                        </Button>
                                    </Stack>
                                    
                                    {selectedNode.isFolder &&
                                    <Box>                   
                                        <FileDropzone
                                            accept={{ 'image/*': [], 'video/*': [], 'application/pdf': [] }}
                                            maxFiles={10}
                                            files={filesToUpload??[]}
                                            setFiles={setFilesToUpload}
                                            fileIds={fileIds??[]}
                                            setFileIds={setFileIds}
                                            fetchedFiles={fetchedFiles??[]}
                                            setFetchedFiles={setFetchedFiles}
                                            onUploadComplete={handleUploadComplete}
                                        />
                                    </Box>}
                                </Box>
                            </div>
                        )}
                    </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Dialog open={showAddFolderDialog} onClose={handleFolderDialogClose}>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    handleAddFolderSubmit();
                }}
            >
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="folderName"
                        label="Название папки"
                        type="text"
                        fullWidth
                        value={folderName}
                        onChange={handleFolderNameChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFolderDialogClose}>
                        Отмена
                    </Button>
                    <Button type="submit" color="primary">
                        Создать
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

        <Dialog open={showRenameFolderDialog} onClose={handleRenameFolderDialogClose}>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    handleRenameFolderSubmit();
                }}
            >
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="folderName"
                        label="Название папки"
                        type="text"
                        fullWidth
                        value={folderName}
                        onChange={handleFolderNameChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRenameFolderDialogClose}>
                        Отмена
                    </Button>
                    <Button type="submit" color="primary">
                        Переименовать
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

        <ConfirmationDialog
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
            title={t(selectedNode?.isFolder?'Удаление папки':'Удаление файла')}
            description={t(selectedNode?.isFolder?'Вы уверены, что хотите удалить папку?':'Вы уверены, что хотите удалить файл?')}
            onConfirm={() => {
                setOpenDeleteDialog(false);
                handleDeleteFolder();
            }}
        />
    </PageLayout>
}

export default Documents;