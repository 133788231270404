import React from "react";
function renderStatus(status) {
    switch(status) {
        case "active":
            return "В работе";
        case "accident":
            return "Авария";
        case "completed":
            return "Закрыто";
        case "pending":
            return "На рассмотрении";
        default:
            return status;
    }
}
export default renderStatus;