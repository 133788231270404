import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {useTranslation} from "react-i18next";

const ConfirmationDialog = ({ open, setOpen, onClose, onConfirm, title, description, negativeBtnText, positiveBtnText }) => {
    const {t} = useTranslation();
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        setOpen(false);
    };
    if (!title) {
        title = t("Удаление записи");
    }
    if (!description) {
        description = t("Вы действительно хотите удалить запись?");
    }

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {description}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>{negativeBtnText??t('Нет')}</Button>
            <Button onClick={onConfirm} autoFocus>
                {positiveBtnText??t('Да')}
            </Button>
        </DialogActions>
    </Dialog>
};

export default ConfirmationDialog;