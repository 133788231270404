import React, {useEffect, useState, useCallback} from 'react';
import {
    LinearProgress,
    Stack,
    Button,
    Card,
    CardContent,
    TextField,
    Box,
    Divider,
    Typography
} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import toast from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PageLayout from "../../components/layouts/PageLayout";
import { generateEndpoints } from '../../services/api';
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import {FileDropzone} from "../../components/common/FileDropzone";
import {FileList} from "../../components/common/FileList";
import {useSelector} from "react-redux";
const { useGetRequestByIdQuery, useUpdateRequestMutation, useCreateRequestMutation, useDeleteRequestMutation } = generateEndpoints('request');
const { useGetUnitsQuery } = generateEndpoints('unit');
const { useGetUnitTypesQuery } = generateEndpoints('unit-type');
const { useGetUsersQuery } = generateEndpoints('user');

const RequestEdit = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const selectedOrganization = useSelector((state) => state.organization.selected);
    const { data: units = [] } = useGetUnitsQuery(selectedOrganization?.id || null);
    const { data: unitTypes = [] } = useGetUnitTypesQuery(selectedOrganization?.id || null);
    const { data: users = [] } = useGetUsersQuery(selectedOrganization?.id || null);
    const { data: fetchedRequest = {}, isFetching = false } = id ? useGetRequestByIdQuery(id) : { data: {} };
    const [updateRequest, { isLoading }] = useUpdateRequestMutation();
    const [createRequest] = useCreateRequestMutation();
    const [deleteRequest] = useDeleteRequestMutation();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const navigate = useNavigate();

    const [photoIds, setPhotoIds] = useState(null);
    const [videoIds, setVideoIds] = useState(null);
    const [fetchedPhotos, setFetchedPhotos] = useState([]);
    const [fetchedVideos, setFetchedVideos] = useState([]);
    const [photosToUpload, setPhotosToUpload] = useState([]);
    const [videosToUpload, setVideosToUpload] = useState([]);

    const formik = useFormik({
        initialValues: {
            unit: fetchedRequest?.unit || '',
            model: fetchedRequest?.model || '',
            model_type: fetchedRequest?.model_type || 'УНР',
            phone: fetchedRequest?.phone || '',
            fio: fetchedRequest?.fio || '',
            email: fetchedRequest?.email || '',
            position: fetchedRequest?.position || '',
            status: fetchedRequest?.status || '',
            description: fetchedRequest?.description || '',
            chronology: fetchedRequest?.chronology || '',
            actions_description: fetchedRequest.actions_description || '',
            user_id: fetchedRequest?.user_id || '',
            photos: [],
            videos: []
        },
        validationSchema: Yup.object({
            unit: Yup.string().required('Обязательно для заполнения'),
            description: Yup.string().required('Обязательно для заполнения'),
            chronology: Yup.string().required('Обязательно для заполнения'),
            actions_description: Yup.string().required('Обязательно для заполнения'),
            user_id: Yup.string().required('Обязательно для заполнения'),
        }),
        onSubmit: async (values, helpers) => {
            try {
                let result = null;
                if (id) {
                    result = await updateRequest({ id, data: {
                            organization_id: selectedOrganization.id,
                            ...values,
                            photos: photoIds,
                            videos: videoIds
                        }});
                } else {
                    result = await createRequest({ data: {
                            organization_id: selectedOrganization.id,
                            ...values,
                            photos: photoIds,
                            videos: videoIds
                        } });
                }
                if (result.error){
                    toast.error(result.error.error);
                    return false;
                }
                toast.success('Обращение сохранено успешно');
                navigate('/requests');
            } catch (err) {
                console.error(err);
                toast.error('Ошибка сервера');
            }
            return false;
        }
    });

    useEffect(() => {
        if (fetchedRequest.id) {
            formik.setValues(fetchedRequest);
            if (fetchedRequest.photos) {
                setFetchedPhotos(fetchedRequest.photos)
                setPhotoIds(fetchedRequest.photos.map(file => file.id));
            }
            if (fetchedRequest.videos) {
                setFetchedVideos(fetchedRequest.videos)
                setVideoIds(fetchedRequest.videos.map(file => file.id));
            }
        }
    }, [fetchedRequest]);

    useEffect(() => {
        if (!id && users.length) {
            formik.setValues({user_id: users[0].id});
        }
    }, [users]);

    useEffect(() => {
        if (!id && units.length) {
            formik.setValues({unit: units[0].id});
        }
    }, [units]);

    useEffect(() => {
        setPhotoIds(fetchedPhotos.map(file => file.id));
    }, [fetchedPhotos]);

    useEffect(() => {
        setVideoIds(fetchedVideos.map(file => file.id));
    }, [fetchedVideos]);


    return <PageLayout
        title={id ? "Редактирование обращения " + id:"Создание обращения"}
    >
        {isFetching ? <LinearProgress />:
            <form onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent sx={{ p: 2 }}>
                        <Stack spacing={2}>

                        <TextField
                            error={!!(formik.touched.status && formik.errors.status)}
                            helperText={formik.touched.status && formik.errors.status}
                            label={t('Статус')}
                            name="status"
                            onChange={formik.handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={formik.values.status}
                        >
                            <option value='pending'>На рассмотрении</option>
                            <option value='active'>В работе</option>
                            <option value='accident'>Авария</option>
                            <option value='completed'>Закрыто</option>
                        </TextField>
                        <TextField
                            error={!!(formik.touched.model && formik.errors.model)}
                            helperText={formik.touched.model && formik.errors.model}
                            label={t('Тип установки')}
                            name="model"
                            onChange={formik.handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={formik.values.model}
                        >
                            {unitTypes.map((unit, index) => {
                                return <option
                                    key={'model_type-'+index}
                                    value={unit.model}
                                >
                                    {unit.model}
                                </option>
                            })}
                        </TextField>
                        <TextField
                            error={!!(formik.touched.unit && formik.errors.unit)}
                            helperText={formik.touched.unit && formik.errors.unit}
                            label={t('Номер установки')}
                            name="unit"
                            onChange={formik.handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={formik.values.unit}
                        >
                            {units.map((unit, index) => {
                                return <option
                                    key={'unit-'+index}
                                    value={unit.id}
                                >
                                    {unit.serial}
                                </option>
                            })}
                        </TextField>

                        <TextField
                            error={!!(formik.touched.description && formik.errors.description)}
                            fullWidth
                            multiline
                            helperText={formik.touched.description && formik.errors.description}
                            label={t('Опишите нештатную ситуацию')}
                            name="description"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required
                            value={formik.values.description}
                        />

                        <TextField
                            error={!!(formik.touched.chronology && formik.errors.chronology)}
                            fullWidth
                            multiline
                            helperText={formik.touched.chronology && formik.errors.chronology}
                            label={t('Что предшествовало нештатной ситуации?')}
                            name="chronology"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required
                            value={formik.values.chronology}
                        />

                        <TextField
                            error={!!(formik.touched.actions_description && formik.errors.actions_description)}
                            fullWidth
                            multiline
                            helperText={formik.touched.actions_description && formik.errors.actions_description}
                            label={t('Какие мероприятия произведены в текущий момент?')}
                            name="actions_description"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required
                            value={formik.values.actions_description}
                        />
                        <Stack direction={"row"} spacing={2}>
                            <TextField
                                error={!!(formik.touched.date && formik.errors.date)}
                                fullWidth
                                multiline
                                type="datetime-local"
                                helperText={formik.touched.date && formik.errors.date}
                                label={t('Дата')}
                                name="date"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                required
                                value={formik.values.date}
                            />
                            <TextField
                                error={!!(formik.touched.time && formik.errors.time)}
                                fullWidth
                                multiline
                                type="time"
                                helperText={formik.touched.time && formik.errors.time}
                                label={t('Время')}
                                name="time"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                required
                                value={formik.values.time}
                            />
                        </Stack>

                        <Divider />

                        <Typography variant="h6" gutterBottom>
                            {t('Контактные данные')}
                        </Typography>

                            <TextField
                                error={!!(formik.touched.user_id && formik.errors.user_id)}
                                helperText={formik.touched.user_id && formik.errors.user_id}
                                label={t('Автор запроса')}
                                name="user_id"
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                value={formik.values.user_id}
                            >
                                {users.map((user, index) => {
                                    return <option
                                        key={'unit-'+index}
                                        value={user.id}
                                    >
                                        {user.name} {user.lastname} {user.job_title?`(${user.job_title})`:''}
                                    </option>
                                })}
                            </TextField>

                            <TextField
                                error={!!(formik.touched.fio && formik.errors.fio)}
                                fullWidth
                                multiline
                                helperText={formik.touched.fio && formik.errors.fio}
                                label={t('Ф.И.О. контактного лица')}
                                name="email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                required
                                value={formik.values.fio}
                            />
                            <TextField
                                error={!!(formik.touched.phone && formik.errors.phone)}
                                fullWidth
                                multiline
                                helperText={formik.touched.phone && formik.errors.phone}
                                label={t('Телефон')}
                                name="phone"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                required
                                value={formik.values.phone}
                            />
                            <TextField
                                error={!!(formik.touched.email && formik.errors.email)}
                                fullWidth
                                multiline
                                helperText={formik.touched.email && formik.errors.email}
                                label={t('Email')}
                                name="email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                required
                                value={formik.values.email}
                            />
                            <TextField
                                error={!!(formik.touched.position && formik.errors.position)}
                                fullWidth
                                multiline
                                helperText={formik.touched.position && formik.errors.position}
                                label={t('Должность')}
                                name="position"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                required
                                value={formik.values.position}
                            />
                        <Divider />
                            <Typography variant="h6" gutterBottom>
                                {t('Изображения')}
                            </Typography>
                        <FileDropzone
                            accept={{ 'image/*': [] }}
                            maxFiles={10}
                            files={photosToUpload}
                            setFiles={setPhotosToUpload}
                            fileIds={photoIds}
                            setFileIds={setPhotoIds}
                            fetchedFiles={fetchedPhotos}
                            setFetchedFiles={setFetchedPhotos}
                        />
                        <FileList files={fetchedPhotos} setFiles={setFetchedPhotos} fileIds={photoIds} setFileIds={setPhotoIds} />
                        <Divider />
                            <Typography variant="h6" gutterBottom>
                                {t('Видео')}
                            </Typography>
                        <FileDropzone
                            accept={{ 'video/*': [] }}
                            maxFiles={3}
                            files={videosToUpload}
                            setFiles={setVideosToUpload}
                            fileIds={videoIds}
                            setFileIds={setVideoIds}
                            fetchedFiles={fetchedVideos}
                            setFetchedFiles={setFetchedVideos}
                        />
                        <FileList files={fetchedVideos} setFiles={setFetchedVideos} fileIds={videoIds} setFileIds={setVideoIds} />

                        </Stack>
                    </CardContent>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        flexWrap="wrap"
                        spacing={3}
                        sx={{ p: 3 }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => navigate(-1)}
                        >
                            {t('Отмена')}
                        </Button>
                        <Box sx={{ flex: 1 }}></Box>
                        {id ? <Button
                            disabled={formik.isSubmitting}
                            variant="outlined"
                            onClick={() => setOpenDeleteDialog(true)}
                            color="error"
                        >
                            {t('Удалить')}
                        </Button>: null}
                        <Button
                            disabled={formik.isSubmitting}
                            type="submit"
                            variant="contained"
                        >
                            {t('Сохранить')}
                        </Button>
                    </Stack>
                </Card>
                <ConfirmationDialog
                    open={openDeleteDialog}
                    setOpen={setOpenDeleteDialog}
                    onConfirm={async () => {
                        setOpenDeleteDialog(false);
                        const result = await deleteRequest( id );
                        if (result.error){
                            toast.error(result.error.error);
                            return false;
                        }
                        toast.success('Обращение удалено');
                        navigate('/requests');
                    }}
                />
            </form>
        }
    </PageLayout>

};

export default RequestEdit;
