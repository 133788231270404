import React from 'react';
import ReactDOM from "react-dom/client";
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from './theme';
import './locales/i18n';
import App from './App';
import { Provider, useSelector } from 'react-redux';
import { store } from './store';

const Root = () => {
    const paletteMode = useSelector((state) => state.theme.paletteMode);

    return (
        <React.StrictMode>
            <ThemeProvider theme={createTheme({
                colorPreset: 'indigo',
                responsiveFontSizes: true,
                paletteMode,
                contrast: 'high'
            })}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </React.StrictMode>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Root />
    </Provider>
);