import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { camelCase, upperFirst } from 'lodash';
import { useSelector } from 'react-redux';

//const baseUrl = 'http://localhost:8088/api/v1';
const baseUrl = '/api/v1';

const baseApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const jwt = getState().user.jwt;
            if (jwt) {
                headers.set('Authorization', `Bearer ${jwt}`)
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
})

const generatedEntities = {};

export const generateEndpoints = (entityName) => {
    const camelEntityName = upperFirst(camelCase(entityName));

    if (generatedEntities[camelEntityName]) {
        return generatedEntities[camelEntityName];
    }

    const entityApi = baseApi.injectEndpoints({
        endpoints: (builder) => ({
            [`get${camelEntityName}s`]: builder.query({
                query: (selectedOrganization) => `/${entityName}s?`+
                    (camelEntityName!="UnitType"?(selectedOrganization?`selectedOrganization=${selectedOrganization}`:''):''),
                providesTags: [`${camelEntityName}s`],
                invalidatesTags: camelEntityName==="Organization"?[`Users`,'Documents']:null,
                transformResponse: (response) => {
                    return response.data;
                }
            }),
            [`get${camelEntityName}ById`]: builder.query({
                query: (id) => `/${entityName}s/${id}`,
                providesTags: [`${camelEntityName}s`],
                transformResponse: (response) => {
                    return response.data;
                }
            }),
            [`create${camelEntityName}`]: builder.mutation({
                query: ({ data }) => ({
                    url: `/${entityName}s`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: [`${camelEntityName}s`],
                transformResponse: (response) => {
                    return response.data;
                }
            }),
            [`delete${camelEntityName}`]: builder.mutation({
                query: (id) => ({
                    url: `/${entityName}s/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: [`${camelEntityName}s`],
                transformResponse: (response) => {
                    return response.data;
                }
            }),
            [`update${camelEntityName}`]: builder.mutation({
                query: ({ id, data }) => ({
                    url: `/${entityName}s/${id}`,
                    method: 'PUT',
                    body: data,
                }),
                invalidatesTags: [`${camelEntityName}s`],
                transformResponse: (response) => {
                    return response.data;
                }
            }),
        }),
    })

    const {
        [`useGet${camelEntityName}sQuery`]: useGetEntitiesQuery,
        [`useGet${camelEntityName}ByIdQuery`]: useGetEntityByIdQuery,
        [`useCreate${camelEntityName}Mutation`]: useCreateEntityMutation,
        [`useDelete${camelEntityName}Mutation`]: useDeleteEntityMutation,
        [`useUpdate${camelEntityName}Mutation`]: useUpdateEntityMutation
    } = entityApi;

    const entityEndpoints = {
        [`useGet${camelEntityName}sQuery`]: useGetEntitiesQuery,
        [`useGet${camelEntityName}ByIdQuery`]: useGetEntityByIdQuery,
        [`useCreate${camelEntityName}Mutation`]: useCreateEntityMutation,
        [`useDelete${camelEntityName}Mutation`]: useDeleteEntityMutation,
        [`useUpdate${camelEntityName}Mutation`]: useUpdateEntityMutation
    };

    generatedEntities[camelEntityName] = entityEndpoints;

    return entityEndpoints;
}
export default baseApi;
export { baseUrl };