import { createAsyncThunk } from '@reduxjs/toolkit';
import baseApi from './api';

export const params = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUnitParamsForAPeriod : builder.query({
            query: ({ id, period, parameter }) => ({
                url: `/units/${id}/params?period=${period}&parameter=${parameter}`,
            }),
            providesTags: ['unit'],
            transformResponse: (response) => {
                return response.data;
            }
        }),
    }),
})

export const { useGetUnitParamsForAPeriodQuery } = params;