import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from "react-router-dom";
import { store } from './store';
import router from './router';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </LocalizationProvider>
    );
}

export default App;