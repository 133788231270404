import React, {useEffect, useState} from 'react';
import {
    LinearProgress,
    Stack,
    Button,
    Card,
    CardContent,
    TextField, Box
} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import toast from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PageLayout from "../../components/layouts/PageLayout";
import { generateEndpoints } from '../../services/api';
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
const { useGetOrganizationByIdQuery, useUpdateOrganizationMutation, useCreateOrganizationMutation, useDeleteOrganizationMutation  } = generateEndpoints('organization');

const OrganizationEdit = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const { data: fetchedOrganization = {}, isFetching } = useGetOrganizationByIdQuery(id);
    const [updateOrganization, { isLoading }] = useUpdateOrganizationMutation();
    const [createOrganization] = useCreateOrganizationMutation();
    const [deleteOrganization] = useDeleteOrganizationMutation();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: fetchedOrganization.name || '',
            submit: null
        },
        validationSchema: Yup.object({
            name: Yup
                .string()
                .max(255)
                .required('Название обязательно для заполнения')
        }),
        onSubmit: async (values, helpers) => {
            try {
                let result = null;
                if (id) {
                    result = await updateOrganization({ id, data: { name: values.name } });
                } else {
                    result = await createOrganization({ data: { name: values.name } });
                }
                if (result.error){
                    toast.error(result.error.error);
                    return false;
                }
                toast.success('Организация сохранена');
                navigate('/settings');
            } catch (err) {
                console.error(err);
                toast.error('Ошибка сервера');
            }
            return false;
        }
    });

    useEffect(() => {
        if (fetchedOrganization.name) {
            formik.setValues({name: fetchedOrganization.name});
        }
    }, [fetchedOrganization]);

    return <PageLayout
        title={id?"Редактирование организации "+(fetchedOrganization.name??''):"Создание организации"}
    >
        {isFetching?<LinearProgress />:
            <form
                onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent sx={{ p: 2 }}>
                        <TextField
                            error={!!(formik.touched.name && formik.errors.name)}
                            fullWidth
                            helperText={formik.touched.name && formik.errors.name}
                            label={t('Название')}
                            name="name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required
                            value={formik.values.name}
                        />
                    </CardContent>
                    <Stack
                        direction={{
                            xs: 'column',
                            sm: 'row'
                        }}
                        flexWrap="wrap"
                        spacing={3}
                        sx={{ p: 3 }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => navigate(-1)}
                        >
                            {t('Отмена')}
                        </Button>
                        <Box sx={{ flex: 1 }}></Box>
                        {id ? <Button
                            disabled={formik.isSubmitting}
                            variant="outlined"
                            onClick={() => setOpenDeleteDialog(true)}
                            color="error"
                        >
                            {t('Удалить')}
                        </Button>: null}
                        <Button
                            disabled={formik.isSubmitting}
                            type="submit"
                            variant="contained"
                        >
                            {t('Сохранить')}
                        </Button>
                    </Stack>
                </Card>
                <ConfirmationDialog
                    open={openDeleteDialog}
                    setOpen={setOpenDeleteDialog}
                    onConfirm={async () => {
                        setOpenDeleteDialog(false);
                        const result = await deleteOrganization( id );
                        if (result.error){
                            toast.error(result.error.error);
                            return false;
                        }
                        toast.success('Организация удалена');
                        navigate('/settings');
                    }}
                />
            </form>
        }
    </PageLayout>

    ;
};

export default OrganizationEdit;