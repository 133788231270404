import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import Upload01Icon from '@untitled-ui/icons-react/build/esm/Upload01';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import toast from 'react-hot-toast';
import {
    Avatar,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    SvgIcon,
    Tooltip,
    Typography
} from '@mui/material';
import {FileIcon} from './FileIcon';
import {baseUrl} from '../../services/api';
import axios from "axios";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

export const bytesToSize = (bytes, decimals = 2) => {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const FileDropzone = (props) => {
    const {t} = useTranslation();
    const {caption, files = [], fileIds = [], setFiles, setFileIds, fetchedFiles = [], setFetchedFiles, maxFiles = 5, onUploadComplete, ...other} = props;

    const hasAnyFiles = files.length > 0;
    other.onDrop = useCallback((newFiles) => {
        if (fetchedFiles.length + newFiles.length > maxFiles) {
            toast.error(t('Превышено максимальное количество файлов'));
        } else {
            setFiles((prevFiles) => {
                return [...prevFiles, ...newFiles];
            });
        }
    }, [files.length, fetchedFiles.length]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({...other, maxFiles: maxFiles - fetchedFiles.length});

    const onRemove = useCallback((file) => {
        setFiles((prevFiles) => {
            return prevFiles.filter((_file) => _file.path !== file.path);
        });
    }, []);

    const onRemoveAll = useCallback(() => {
        setFiles([]);
    }, []);
    const onUpload = async () => {
        const url = baseUrl + "/upload";

        const formData = new FormData();

        // Add files to formData
        files.forEach((file, index) => {
            formData.append(`file${index + 1}`, file);
        });

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            const uploadedFileIds = response.data.data.ids;
            if (fileIds){
                setFileIds([...fileIds, ...uploadedFileIds]);
                if (setFetchedFiles) setFetchedFiles([...fetchedFiles, ...response.data.data.files]);
            } else {
                setFileIds(uploadedFileIds);
            }

            setFiles([]);
            if (typeof onUploadComplete == 'function') onUploadComplete(response.data.data.files);
            toast.success('Файлы загружены');
        } catch (error) {
            console.error("Error uploading file", error);
            toast.error('Ошибка загрузки файлов');
        }
    };

    return (
        <div>
            <Box
                sx={{
                    alignItems: 'center',
                    border: 1,
                    borderRadius: 1,
                    borderStyle: 'dashed',
                    borderColor: 'divider',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    outline: 'none',
                    p: 6,
                    ...(isDragActive && {
                        backgroundColor: 'action.active',
                        opacity: 0.5
                    }),
                    '&:hover': {
                        backgroundColor: 'action.hover',
                        cursor: 'pointer',
                        opacity: 0.5
                    }
                }}
                {...getRootProps()}>
                <input {...getInputProps()} />
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    <Avatar
                        sx={{
                            height: 64,
                            width: 64
                        }}
                    >
                        <SvgIcon>
                            <Upload01Icon/>
                        </SvgIcon>
                    </Avatar>
                    <Stack spacing={1}>
                        <Typography
                            sx={{
                                '& span': {
                                    textDecoration: 'underline'
                                }
                            }}
                            variant="h6"
                        >
                            <span>{t('Загрузите файл')}</span> {t('или перетащите его в это поле')}
                        </Typography>

                        <Typography
                            color="text.secondary"
                            variant="body2"
                        >
                            {caption??t('Максимальный размер файла 5 МБ')}
                        </Typography>

                    </Stack>
                </Stack>
            </Box>
            {hasAnyFiles && (
                <Box sx={{mt: 2}}>
                    <List>
                        {files.map((file) => {
                            const extension = file.name.split('.').pop();

                            return (
                                <ListItem
                                    key={file.path}
                                    sx={{
                                        border: 1,
                                        borderColor: 'divider',
                                        borderRadius: 1,
                                        '& + &': {
                                            mt: 1
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <FileIcon extension={extension}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={file.name}
                                        primaryTypographyProps={{variant: 'subtitle2'}}
                                        secondary={bytesToSize(file.size)}
                                    />
                                    <Tooltip title="Remove">
                                        <IconButton
                                            edge="end"
                                            onClick={() => onRemove?.(file)}
                                        >
                                            <SvgIcon>
                                                <XIcon/>
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>
                                </ListItem>
                            );
                        })}
                    </List>
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-end"
                        spacing={2}
                        sx={{mt: 2}}
                    >
                        <Button
                            color="inherit"
                            onClick={onRemoveAll}
                            size="small"
                            type="button"
                        >
                            {t('Удалить все')}
                        </Button>
                        <Button
                            onClick={onUpload}
                            size="small"
                            type="button"
                            variant="contained"
                        >
                            {t('Загрузить файлы')}
                        </Button>
                    </Stack>
                </Box>
            )}
        </div>
    );
};

FileDropzone.propTypes = {
    caption: PropTypes.string,
    files: PropTypes.array,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    onUpload: PropTypes.func,
    // From Dropzone
    accept: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired),
    disabled: PropTypes.bool,
    getFilesFromEvent: PropTypes.func,
    maxFiles: PropTypes.number,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    noClick: PropTypes.bool,
    noDrag: PropTypes.bool,
    noDragEventsBubbling: PropTypes.bool,
    noKeyboard: PropTypes.bool,
    onDrop: PropTypes.func,
    onDropAccepted: PropTypes.func,
    onDropRejected: PropTypes.func,
    onFileDialogCancel: PropTypes.func,
    preventDropOnDocument: PropTypes.bool
};
