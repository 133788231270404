import {
    createBrowserRouter
} from "react-router-dom";

import Home from './pages/Home';
import Login from './pages/Login';
import Settings from './pages/Settings';
import Documents from './pages/Documents';
import MainLayout from './components/layouts/MainLayout';
import LoginLayout from './components/layouts/LoginLayout';
import OrganizationsEdit from './pages/Settings/OrganizationsEdit';
import UnitTypeEdit from "./pages/Settings/UnitTypeEdit";
import BannerEdit from "./pages/Settings/BannerEdit";
import NotificationEdit from "./pages/Settings/NotificationEdit";
import UserEdit from "./pages/Users/UserEdit";
import Users from "./pages/Users";
import Units from "./pages/Units";
import UnitEdit from "./pages/Units/UnitEdit";
import Requests from "./pages/Requests";
import RequestEdit from "./pages/Requests/RequestEdit";
import {RequireAuth} from "./components/common/Auth/RequireAuth";

const routes = [
    {
        element: <RequireAuth><MainLayout /></RequireAuth>,
        children: [
            {
                path: "/",
                element: <Units/>,
            },
            {
                path: '/settings',
                element: <Settings/>,
            },
            {
                path: '/settings/organizations/add',
                element: <OrganizationsEdit/>,
            },
            {
                path: '/settings/organizations/:id',
                element: <OrganizationsEdit/>,
            },
            {
                path: '/settings/unit-types/add',
                element: <UnitTypeEdit/>,
            },
            {
                path: '/settings/unit-types/:id',
                element: <UnitTypeEdit/>,
            },
            {
                path: '/settings/banners/add',
                element: <BannerEdit/>,
            },
            {
                path: '/settings/banners/:id',
                element: <BannerEdit/>,
            },
            {
                path: '/settings/notifications/add',
                element: <NotificationEdit/>,
            },
            {
                path: '/settings/notifications/:id',
                element: <NotificationEdit/>,
            },
            {
                path: '/units',
                element: <Units/>,
            },
            {
                path: '/units/add',
                element: <UnitEdit/>,
            },
            {
                path: '/units/:id',
                element: <UnitEdit/>,
            },
            {
                path: '/users',
                element: <Users/>,
            },
            {
                path: '/users/add',
                element: <UserEdit/>,
            },
            {
                path: '/users/:id',
                element: <UserEdit/>,
            },
            {
                path: '/docs',
                element: <Documents/>,
            },
            {
                path: '/requests',
                element: <Requests/>,
            },
            {
                path: '/requests/add',
                element: <RequestEdit/>,
            },
            {
                path: '/requests/:id',
                element: <RequestEdit/>,
            },
        ],
    },
    {
        element: <LoginLayout />,
        path: "/login",
        children: [
            {
                path: "/login",
                element: <Login/>,
            },
            {
                path: '/login/forgot',
                element: <div>forgot password</div>,
            }
        ],
    },
];

export default createBrowserRouter(routes);