import React from 'react';
import { Box, Container, Stack } from '@mui/material';
import {alpha, styled, useTheme} from '@mui/material/styles';
import { Logo } from '../../common/Logo';
import {Outlet} from "react-router-dom";
import { Toaster as HotToaster } from 'react-hot-toast';

const TOP_NAV_HEIGHT = 64;

const LayoutRoot = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    height: '100%'
}));

const LoginLayout = ({children}) => {
    const theme = useTheme();
    return (
        <LayoutRoot>
            <Box
                component="header"
                sx={{
                    left: 0,
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    zIndex: (theme) => theme.zIndex.appBar
                }}
            >
                <Container maxWidth="lg">
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{height: TOP_NAV_HEIGHT}}
                    >
                        <Stack
                            alignItems="center"
                            direction="row"
                            display="inline-flex"
                            spacing={1}
                            sx={{textDecoration: 'none'}}
                        >
                            <Box
                                sx={{
                                    display: 'inline-flex',
                                    height: 24,
                                    width: 24
                                }}
                            >
                                <Logo/>
                            </Box>
                            <Box
                                sx={{
                                    color: 'text.primary',
                                    fontFamily: '\'Plus Jakarta Sans\', sans-serif',
                                    fontSize: 14,
                                    fontWeight: 800,
                                    lineHeight: 2.5,
                                    '& span': {
                                        color: 'primary.main'
                                    }
                                }}
                            >
                                Иждрил
                            </Box>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    flex: '1 1 auto'
                }} component="main" flexGrow={1}>
                <Container
                    maxWidth="sm"
                    sx={{
                        py: {
                            xs: '60px',
                            md: '120px'
                        }
                    }}
                >
                    <Outlet/>
                </Container>
            </Box>
            <HotToaster
                position="bottom-right"
                toastOptions={{
                    style: {
                        backdropFilter: 'blur(6px)',
                        background: alpha(theme.palette.neutral[900], 0.8),
                        color: theme.palette.common.white,
                        boxShadow: theme.shadows[16]
                    }
                }}
            />
        </LayoutRoot>
    );
};
export default LoginLayout;