import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, Tab, Tabs, Divider, Stack, LinearProgress} from '@mui/material';
import PageLayout from './../../components/layouts/PageLayout';
import PlainTable from "../../components/common/PlainTable";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import { generateEndpoints } from '../../services/api';
const { useGetRequestsQuery } = generateEndpoints('request');
import {useSelector} from "react-redux";
import renderStatus from "../../components/features/Requests/renderStatus";
function Requests() {
    const {t} = useTranslation();
    const selectedOrganization = useSelector((state) => state.organization.selected);

    const { data: requests = [], isFetching } = useGetRequestsQuery(selectedOrganization?.id || null);

    const columns = [
        {
            title: t('Дата'),
            dataKey: "created_at",
        },
        {
            title: t('Описание'),
            dataKey: "description",
        },
        {
            title: t('Статус'),
            dataKey: "status",
            render: (unit) => {
                return <div>{renderStatus(unit.status)}</div>
            },
        },
        {
            title: t('Действия'),
            dataKey: "",
            render: (request) => (
                <Link to={`/requests/${request.id}`}><Button variant={"outlined"}>{t('Редактировать')}</Button></Link>
            ),
        },
    ];
    return <PageLayout
        title={"Обращения"}
        actions={[{label: "Добавить обращение", href: "/requests/add"}]}
    >
        <Card><CardContent>
            {isFetching?<LinearProgress />:
                <PlainTable columns={columns} data={requests} />
            }
        </CardContent>
        </Card>
    </PageLayout>
}

export default Requests;