function renderStatus(status) {
    switch(status) {
        case "ok":
            return "В работе";
        case "not_available":
            return "Нет сигнала";
        case "accident":
            return "Авария";
        case "pending":
            return "Не в работе";
        default:
            return status;
    }
}
export default renderStatus;