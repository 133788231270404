import React, { useCallback, useMemo } from 'react';
import {Box, Tab, Tabs, Divider, Stack, Card, CardContent} from '@mui/material';
import {useDispatch} from "react-redux";

const TabsLayout = ({ tabs, currentTab, setCurrentTab }) => {

    const dispatch = useDispatch();
    const handleTabsChange = (event, value) => {
        dispatch(setCurrentTab(value));
    };

    const renderCurrentTabComponent = useMemo(
        () => tabs.find(tab => tab.value === currentTab)?.component,
        [currentTab, tabs]
    );

    return (
        <Stack spacing={2}>
            <Box>
                <Tabs
                    indicatorColor="primary"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    textColor="primary"
                    value={currentTab}
                    variant="scrollable"
                >
                    {tabs.map((tab) => (
                        <Tab key={tab.value} label={tab.label} value={tab.value} />
                    ))}
                </Tabs>
                <Divider />
            </Box>

            <Card>
                <CardContent>
                    {renderCurrentTabComponent}
                </CardContent>
            </Card>
        </Stack>
    );
};

export default TabsLayout;