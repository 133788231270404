import React from 'react';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import {
    Container,
    Box,
    Button,
    Stack,
    Typography,
    SvgIcon,
    Grid,
    useMediaQuery
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

function PageLayout({ title, actions, ...props }) {
    const {t} = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    return (
        <Container maxWidth="xl" sx={{flexGrow: 1, py: 8}}>
            <Stack spacing={4}>
                <Grid container direction={isMobile ? "column" : "row"} justifyContent="space-between" spacing={2}>
                    <Grid item xs={12} sm>
                        <Typography variant="h4">{title?t(title):''}</Typography>
                    </Grid>
                    {actions&&actions.length?<Grid item xs={12} sm>
                        <Stack direction="row" spacing={4} justifyContent={isMobile?"flex-start":"flex-end"}>
                            {actions.map((action, index) => {
                                return <Button
                                            key={index}
                                            startIcon={
                                                <SvgIcon>
                                                    {action.icon ? action.icon : <PlusIcon />}
                                                </SvgIcon>
                                            }
                                            variant="contained"
                                            onClick={action.clickHandler?()=>action.clickHandler():action.href?()=>{
                                                navigate(action.href)
                                            }:null}
                                        >
                                            {t(action.label)}
                                        </Button>
                            })}
                        </Stack>
                    </Grid>:null}
                </Grid>
                <Box
                    sx={{
                        position: 'relative'
                    }}
                >
                    {props.children}
                </Box>
            </Stack>

        </Container>
    );
}

export default PageLayout;