import React, {useCallback, useState} from 'react';
import {Box, Button, Card, CardContent, Tab, Tabs, Divider, Stack} from '@mui/material';
import PageLayout from './../../components/layouts/PageLayout';
import TabsLayout from './../../components/layouts/TabsLayout';
import OrganizationsList from "./OrganizationsList";
import UnitTypesList from "./UnitTypesList";
import BannersList from "./BannersList";
import TextsList from "./TextsList";
import NotificationsList from "./NotificationsList";
import MaintenanceList from "./MaintenanceList";
import ParamSettings from "./ParamSettings";
import {useSelector} from "react-redux";
import { setSettingsTab } from "../../store";
import IntegrationSettings from "./IntegrationSettings";
function Settings() {

    const currentTab = useSelector((state) => state.settings.currentTab);

    const tabs = [
        {
            label: 'Организации',
            value: 'organizations',
            component: <OrganizationsList />,
        },
        {
            label: 'Типы УНР',
            value: 'unit_types',
            component: <UnitTypesList />,
        },
        {
            label: 'Баннеры',
            value: 'banners',
            component: <BannersList />,
        },
        {
            label: 'Оповещения',
            value: 'notifications',
            component: <NotificationsList />,
        },
        {
            label: 'Параметры ТО',
            value: 'maintenance',
            component: <MaintenanceList />,
        },
        {
            label: 'Тексты',
            value: 'texts',
            component: <TextsList />,
        },
        {
            label: 'Параметры установок',
            value: 'params',
            component: <ParamSettings />,
        },
        {
            label: 'Интеграция',
            value: 'integration',
            component: <IntegrationSettings />,
        },
    ];
    return <PageLayout
        title={"Администрирование"}
        actions={
           // currentTab === 'organizations'?[{label: "Добавить организацию", href: "/settings/organizations/add"}]:
            currentTab === 'unit_types'?[{label: "Добавить тип", href: "/settings/unit-types/add"}]:
            currentTab === 'notifications'?[{label: "Добавить оповещение", href: "/settings/notifications/add"}]:
            currentTab === 'banners'?[{label: "Добавить баннер", href: "/settings/banners/add"}]:[]
        }
    >
        <TabsLayout tabs={tabs} currentTab={currentTab} setCurrentTab={setSettingsTab}/>
    </PageLayout>
    ;
}

export default Settings;