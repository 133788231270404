import {createSlice} from "@reduxjs/toolkit";
let selectedOrganization = window.localStorage.getItem('organization.selected');
if (selectedOrganization) {
    try {
        selectedOrganization = JSON.parse(selectedOrganization);
    } catch (e) {
        selectedOrganization = null;
    }
}
export default createSlice({
    name: "organization",
    initialState: {
        selected: selectedOrganization? selectedOrganization : {
            id: null,
            name: ''
        },
    },
    reducers: {
        setSelectedOrganization: (state, action) => {
            state.selected = action.payload;
            window.localStorage.setItem('organization.selected', JSON.stringify(state.selected));
        },
    },
});