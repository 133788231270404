import React, {useEffect, useState, useCallback} from 'react';
import {
    LinearProgress,
    Stack,
    Button,
    Card,
    CardContent,
    TextField,
    Box
} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import toast from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PageLayout from "../../components/layouts/PageLayout";
import { generateEndpoints } from '../../services/api';
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import {FileDropzone} from "../../components/common/FileDropzone";
import {FileList} from "../../components/common/FileList";
const { useGetBannerByIdQuery, useUpdateBannerMutation, useCreateBannerMutation, useDeleteBannerMutation  } = generateEndpoints('banner');

const BannerEdit = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const { data: fetchedBanner = {}, isFetching = false } = id ? useGetBannerByIdQuery(id) : { data: {} };
    const [updateBanner, { isLoading }] = useUpdateBannerMutation();
    const [createBanner] = useCreateBannerMutation();
    const [deleteBanner] = useDeleteBannerMutation();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: fetchedBanner.name || '',
            url: fetchedBanner.url || '',
            submit: null
        },
        validationSchema: Yup.object({
            name: Yup
                .string()
                .max(255)
                .required('Название обязательно для заполнения')
        },{
            url: Yup
                .string()
                .max(512)
        }),
        onSubmit: async (values, helpers) => {
            try {
                let result = null;
                if (id) {
                    result = await updateBanner({ id, data: {
                        name: values.name,
                        url: values.url,
                        files: fileIds
                    } });
                } else {
                    result = await createBanner({ data: {
                        name: values.name,
                        url: values.url,
                        files: fileIds
                    } });
                }
                if (result.error){
                    toast.error(result.error.error);
                    return false;
                }
                toast.success('Баннер сохранен');
                navigate('/settings');
            } catch (err) {
                console.error(err);
                toast.error('Ошибка сервера');
            }
            return false;
        }
    });

    useEffect(() => {
        if (fetchedBanner.name) {
            formik.setValues({name: fetchedBanner.name});
        }
        if (fetchedBanner.files) {
            setFetchedFiles(fetchedBanner.files)
            setFileIds(fetchedBanner.files.map(file => file.id));
        }
    }, [fetchedBanner]);

    const [fileIds, setFileIds] = useState(null);
    const [fetchedFiles, setFetchedFiles] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);

    return <PageLayout
        title={id?"Редактирование баннера "+(fetchedBanner.name??''):"Создание баннера"}
    >
        {isFetching?<LinearProgress />:
            <form
                onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent sx={{ p: 2}}>
                        <Stack spacing={2}>
                        <TextField
                            error={!!(formik.touched.name && formik.errors.name)}
                            fullWidth
                            helperText={formik.touched.name && formik.errors.name}
                            label={t('Название')}
                            name="name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required
                            value={formik.values.name}
                        />
                        <TextField
                            error={!!(formik.touched.url && formik.errors.url)}
                            fullWidth
                            helperText={formik.touched.url && formik.errors.url}
                            label={t('Ссылка')}
                            name="url"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.url}
                        />
                        <FileDropzone
                            accept={{ 'image/*': [], 'video/*': [], 'application/pdf': [] }}
                            maxFiles={1}
                            files={filesToUpload}
                            setFiles={setFilesToUpload}
                            fileIds={fileIds}
                            setFileIds={setFileIds}
                            fetchedFiles={fetchedFiles}
                            setFetchedFiles={setFetchedFiles}
                        />
                        <FileList files={fetchedFiles} setFiles={setFetchedFiles} fileIds={fileIds} setFileIds={setFileIds} />
                        </Stack>
                    </CardContent>
                    <Stack
                        direction={{
                            xs: 'column',
                            sm: 'row'
                        }}
                        flexWrap="wrap"
                        spacing={3}
                        sx={{ p: 3 }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => navigate(-1)}
                        >
                            {t('Отмена')}
                        </Button>
                        <Box sx={{ flex: 1 }}></Box>
                        {id ? <Button
                            disabled={formik.isSubmitting}
                            variant="outlined"
                            onClick={() => setOpenDeleteDialog(true)}
                            color="error"
                        >
                            {t('Удалить')}
                        </Button>: null}
                        <Button
                            disabled={formik.isSubmitting}
                            type="submit"
                            variant="contained"
                        >
                            {t('Сохранить')}
                        </Button>
                    </Stack>
                </Card>
                <ConfirmationDialog
                    open={openDeleteDialog}
                    setOpen={setOpenDeleteDialog}
                    onConfirm={async () => {
                        setOpenDeleteDialog(false);
                        const result = await deleteBanner( id );
                        if (result.error){
                            toast.error(result.error.error);
                            return false;
                        }
                        toast.success('Баннер удален');
                        navigate('/settings');
                    }}
                />
            </form>
        }
    </PageLayout>

};

export default BannerEdit;