import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, Tab, Tabs, Divider, Stack, LinearProgress} from '@mui/material';
import PageLayout from './../../components/layouts/PageLayout';
import PlainTable from "../../components/common/PlainTable";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import { generateEndpoints } from '../../services/api';
const { useGetUsersQuery } = generateEndpoints('user');
import { setSelectedOrganization } from "../../store";
import {useSelector} from "react-redux";
function Users() {
    const {t} = useTranslation();
    const selectedOrganization = useSelector((state) => state.organization.selected);

    const { data: users = [], isFetching } = useGetUsersQuery(selectedOrganization?.id || null);

    const columns = [
        {
            title: t('ID'),
            dataKey: "id",
        },
        {
            title: t('Имя'),
            dataKey: "name",
        },
        {
            title: t('Действия'),
            dataKey: "",
            render: (user) => (
                <Link to={`/users/${user.id}`}><Button variant={"outlined"}>{t('Редактировать')}</Button></Link>
            ),
        },
    ];
    return <PageLayout
        title={"Пользователи"}
        actions={[{label: "Добавить пользователя", href: "/users/add"}]}
    >
        <Card><CardContent>
            {isFetching?<LinearProgress />:
                <PlainTable columns={columns} data={users} />
            }
        </CardContent>
        </Card>
    </PageLayout>
}

export default Users;