import * as React from "react";
import {useEffect} from "react";
import {
    useLocation,
    Navigate,
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useGetCurrentUserQuery} from "../../../services/user";
import {LinearProgress} from "@mui/material";
import {setCurrentUser} from "../../../store";

export const RequireAuth = function({ children }) {
    let location = useLocation();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    const { data: userData = {}, isFetching } = useGetCurrentUserQuery();

    useEffect(() => {
        if (userData.id && !currentUser) {
            dispatch(setCurrentUser(userData));
        }
    }, [userData, currentUser]);

    if (!currentUser) {
        if (isFetching) {
            return <LinearProgress />;
        }
        if (userData.id) {

            return children;
        } else {
            return <Navigate to="/login" state={{ from: location }} replace />;
        }
    }
    return children;
}