import { useCallback } from 'react';
import {Link, useNavigate} from "react-router-dom";
import toast from 'react-hot-toast';
import CreditCard01Icon from '@untitled-ui/icons-react/build/esm/CreditCard01';
import Settings04Icon from '@untitled-ui/icons-react/build/esm/Settings04';
import User03Icon from '@untitled-ui/icons-react/build/esm/User03';
import MoonIcon from '@untitled-ui/icons-react/build/esm/MoonEclipse';
import SunIcon from '@untitled-ui/icons-react/build/esm/Sun';
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTheme } from '../../../../store/slices/theme';
import {useTranslation} from "react-i18next";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const handleLogout = useCallback(async () => {
      window.localStorage.clear();
      navigate('/login', { replace: true });
  }, [onClose]);

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.theme);

    const currentUser = useSelector((state) => state.user.currentUser);

    const handleToggleTheme = () => {
        dispatch(toggleTheme());
    };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}>
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">
            {currentUser.name} {currentUser.lastname}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
            {currentUser.email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        <ListItemButton
          component={Link}
          href={'/profile'}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <User03Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                  {t('Профиль')}
              </Typography>
            )}
          />
        </ListItemButton>
        <ListItemButton
            component={Link}
            href={'/settings'}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <Settings04Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                  {t('Настройки')}
              </Typography>
            )}
          />
        </ListItemButton>

          <ListItemButton
              onClick={handleToggleTheme}
              sx={{ borderRadius: 1, px: 1, py: 0.5 }}
          >
              <ListItemIcon>
                  <SvgIcon fontSize="small">
                      {/* use a different icon based on the current theme */}
                      {theme.paletteMode === 'light' ? <MoonIcon /> : <SunIcon />}
                  </SvgIcon>
              </ListItemIcon>
              <ListItemText
                  primary={(
                      <Typography variant="body1">
                          {theme.paletteMode === 'light' ? t('Тёмный режим') : t('Светлый режим')}
                      </Typography>
                  )}
              />
          </ListItemButton>
      </Box>
      <Divider sx={{ my: '0 !important' }} />
      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center'
        }}
      >
        <Button
          color="inherit"
          onClick={handleLogout}
          size="small"
        >
            {t('Выход')}
        </Button>
      </Box>
    </Popover>
  );
};