import { configureStore,  combineReducers } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import baseApi from '../services/api'
import drawer from './slices/drawer'
import organization from './slices/organization'
import settings from './slices/settings'
import userSlice from './slices/user'
import theme from './slices/theme';


export const store = configureStore({
    reducer: combineReducers({
        api: baseApi.reducer,
        drawer: drawer.reducer,
        organization: organization.reducer,
        settings: settings.reducer,
        user: userSlice.reducer,
        theme,
    }),
    middleware: (getDefaultMiddleware) =>
       getDefaultMiddleware().concat(baseApi.middleware),
})

setupListeners(store.dispatch)
export const {
    toggleDrawer,
    setSelectedOrganization,
    setSettingsTab,
    setUnitTab,
    setCurrentUser,
    setJwt
} = {
    ...drawer.actions,
    ...organization.actions,
    ...settings.actions,
    ...userSlice.actions,
};