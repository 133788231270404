import React from 'react';
import {Container, Box, LinearProgress, Stack, TableBody, TableRow, TableCell, Typography, Button} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import PlainTable from "../../components/common/PlainTable";
import {useTranslation} from "react-i18next";
import { generateEndpoints } from '../../services/api';
const { useGetOrganizationsQuery } = generateEndpoints('organization');

const OrganizationsList = () => {
    const {t} = useTranslation();
    const { data: organizations = [], isFetching } = useGetOrganizationsQuery();
    const columns = [
        {
            title: t('Название'),
            width: '100%',
            dataKey: "name",
        },
        {
            title: t('Действия'),
            dataKey: "",
            render: (organization) => (
                <Link to={`/settings/organizations/${organization.id}`}><Button variant={"outlined"}>{t('Редактировать')}</Button></Link>
            ),
        },
    ];

    return <>
        {isFetching?<LinearProgress />:
            <Box>
                <PlainTable columns={columns} data={organizations} />
            </Box>
        }
    </>
    ;
};

export default OrganizationsList;