import React from 'react';
import { useFormik } from 'formik';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Link as MuiLink,
    Stack,
    SvgIcon,
    TextField,
    Typography
} from '@mui/material';
import validationSchema from '../../schemas/user';
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {setSelectedOrganization, setAuth, setJwt} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {loginUser} from '../../services/user';
import toast from "react-hot-toast";

const initialValues = {
    email: 'admin@example.com',
    password: '123456',
    submit: null
};
function Login() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedOrganization = useSelector((state) => state.organization.selected);
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {
            const resultAction = await dispatch(loginUser({ email: values.email, password: values.password }));

            if (loginUser.fulfilled.match(resultAction)) {
                dispatch(setSelectedOrganization(selectedOrganization));
                dispatch(setJwt(resultAction.payload));
                navigate('/users');
            } else if (loginUser.rejected.match(resultAction)) {
                if (resultAction.payload){
                    toast.error(resultAction.payload);
                } else {
                    toast.error('Ошибка авторизации');
                }
            }
        }
    });

    return <Card elevation={16}>
        <CardHeader
            sx={{ pb: 0 }}
            title="Вход"
        />
        <CardContent>
            <form
                noValidate
                onSubmit={formik.handleSubmit}
            >
                <Stack spacing={3}>
                    <TextField
                        autoFocus
                        error={!!(formik.touched.email && formik.errors.email)}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        label="Email"
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="email"
                        value={formik.values.email}
                    />
                    <TextField
                        error={!!(formik.touched.password && formik.errors.password)}
                        fullWidth
                        helperText={formik.touched.password && formik.errors.password}
                        label="Пароль"
                        name="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="password"
                        value={formik.values.password}
                    />
                </Stack>
                <Button
                    fullWidth
                    disabled={formik.isSubmitting}
                    size="large"
                    sx={{ mt: 2 }}
                    type="submit"
                    variant="contained"
                >
                    {formik.isSubmitting ? 'Загрузка...' : 'Вход'}
                </Button>
            </form>
        </CardContent>
    </Card>;
}

export default Login;