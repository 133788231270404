import {List, ListItem, ListItemText, ListItemButton, IconButton, Divider, Typography, Avatar, Modal, Box} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from "react-i18next";
import {useState} from 'react';

export const FileList = ({ files, setFiles, fileIds, setFileIds }) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [previewSrc, setPreviewSrc] = useState('');

    const handleDelete = (id) => {
        if (fileIds && fileIds.length) setFileIds(fileIds.filter(fileId => fileId !== id));
        if (files && files.length) setFiles(files.filter(file => file.id !== id));
    };

    const handleOpen = (event, src) => {
        event.preventDefault();
        event.stopPropagation();
        setPreviewSrc(src);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        files && files.length?
            <>
                <Divider />
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                    {t('Загруженные файлы')}
                </Typography>
                <List>
                    {files.map((file, index) => (
                        <ListItem key={file.id??'file-'+index}>

                            <ListItemButton
                                href={file.src}
                                target="_blank"
                            >
                                <ListItemText
                                    primary={file.filename}
                                    secondary={file.mime}
                                />
                                {file.mime.startsWith('image/') && (
                                    <Avatar variant="square" src={file.src} alt={file.filename} sx={{ width: 100, height: 100 }} onClick={(e) =>
                                        handleOpen(e, file.src)} />
                                )}
                            </ListItemButton>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(file.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{  display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <Box
                        onClick={handleClose}
                        sx={{ width: '80vw', height: '80vh', overflow: 'auto', backgroundColor: 'white',
                            margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={previewSrc} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    </Box>
                </Modal>
            </>
            :null
    );
};
