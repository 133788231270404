import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from "./en/translation.json";
import ruTranslation from "./ru/translation.json";

const resources = {
    en: {
        translation: enTranslation
    },
    ru: {
        translation: ruTranslation
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'ru',
        resources,
        debug: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;