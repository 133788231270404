import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {useNavigate, useParams} from "react-router-dom";
import toast from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';
import {useFormik} from 'formik';
import PageLayout from "../../components/layouts/PageLayout";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import {useSelector} from "react-redux";
import paramsData from "../../store/data/params.json";
import {setUnitTab} from "../../store";
import TabsLayout from "../../components/layouts/TabsLayout";
import {generateEndpoints} from '../../services/api';
import {useGetUnitParamsForAPeriodQuery} from '../../services/params';
const { useGetParametersQuery } = generateEndpoints('parameter');
const {
    useGetUnitByIdQuery,
    useUpdateUnitMutation,
    useCreateUnitMutation,
    useDeleteUnitMutation
} = generateEndpoints('unit');
const {useGetUnitTypesQuery} = generateEndpoints('unit-type');
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import {FileDropzone} from "../../components/common/FileDropzone";
import {FileList} from "../../components/common/FileList";



const UnitEdit = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const {data: fetchedUnit = {}, isFetching = false} = id ? useGetUnitByIdQuery(id) : {data: {}};
    const { data: parameters = [] } = useGetParametersQuery();
    const [selectedParameter, setSelectedParameter] = useState(null);
    const [chartPeriod, setChartPeriod] = useState(90);
    const { data: fetchedChartData = [], refetch: refetchChartData } = id ? useGetUnitParamsForAPeriodQuery({
            id,
            period: chartPeriod,
            parameter: selectedParameter
        },
        {
            skip: !selectedParameter // Skip the query if selectedParameter is not set
        }) : {data: {}};
    const [updateUnit, {isLoading}] = useUpdateUnitMutation();
    const [createUnit] = useCreateUnitMutation();
    const [deleteUnit] = useDeleteUnitMutation();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const selectedOrganization = useSelector((state) => state.organization.selected);
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedParameter) {
            refetchChartData();
        }
    }, [selectedParameter, refetchChartData]);

    useEffect(() => {
        if (fetchedUnit?.id) {
                let paramsArray = JSON.parse(fetchedUnit?.params);
                let paramsObject = {};
                if (paramsArray) {
                    paramsObject = paramsArray.reduce((obj, item) => {
                        obj[item.tag] = item.value;
                        return obj;
                    }, {});
                }
                let ustArray = [];
                let accidentsArray = [];
                try {
                    ustArray = JSON.parse(fetchedUnit?.ust);
                    accidentsArray = JSON.parse(fetchedUnit?.accidents);
                } catch (e) {
                }
                let files = JSON.parse(fetchedUnit?.files);

                formik.setValues({
                    ...fetchedUnit,
                    ...paramsObject,
                    ust: ustArray ?? [],
                    accidents: accidentsArray ?? [],
                    files: files ?? []
                });
        }
    }, [fetchedUnit]);

    let schema = {
        model_id: Yup.number().required('Тип установки обязателен для заполнения'),
        unit_type_to: Yup.string().required('Тип установки для ТО обязателен для заполнения'),
        serial: Yup.string().required('Серийный номер обязателен для заполнения'),
        started_at: Yup.date(),
        emergency_sensors: Yup.boolean(),
        ust_available: Yup.boolean(),
        ust: Yup.array().of(
            Yup.object().shape({
                tag: Yup.string().required('Тэг обязателен для заполнения'),
                name: Yup.string().required('Наименование обязательно для заполнения'),
                type: Yup.string().oneOf(['numeric', 'boolean']).required('Тип обязателен для заполнения'),
                value: Yup.mixed().required('Значение обязательно для заполнения')
            })
        ),
        accidents: Yup.array().of(
            Yup.object().shape({
                description: Yup.string().required('Причина обязательна для заполнения'),
                type: Yup.string().oneOf(['active', 'completed']).required('Статус обязателен для заполнения'),
                date: Yup.mixed().required('Значение обязательно для заполнения')
            })
        )
    }

    const [uploadedFiles, setUploadedFiles] = useState([]); // State to store uploaded files
    const [uploadedFileIds, setUploadedFileIds] = useState([]); // State to store uploaded file IDs


    const formik = useFormik({
        initialValues: {
            files: [],
            model_id: fetchedUnit?.model_id || '',
            serial: fetchedUnit?.serial || '',
            description: fetchedUnit?.description || '',
            status: fetchedUnit?.status || 'ok',
            unit_type_to: fetchedUnit.unit_type_to || 'dvs',
            started_at: fetchedUnit?.started_at ? new Date(fetchedUnit.started_at) : null,
            emergency_sensors: fetchedUnit.emergency_sensors || false,
            ust_available: fetchedUnit.ust_available || false,
            ust: fetchedUnit.ust?JSON.parse(fetchedUnit?.ust) || []:[],
            accidents: fetchedUnit.accidents?JSON.parse(fetchedUnit?.accidents) || []:[]
        },
        onSubmit: async (values, helpers) => {
            try {
                let result = null;

                let paramsArray = paramsData.map(({tag}) => ({
                    tag,
                    value: values[tag],
                }));

                let data = {
                    ...values,
                    params: paramsArray
                };

                if (typeof (id) !== 'undefined') {
                    result = await updateUnit({
                        id,
                        data: {
                            organization_id: selectedOrganization.id,
                            ...data,
                        },
                    });
                } else {
                    result = await createUnit({
                        data: {
                            organization_id: selectedOrganization.id,
                            ...data,
                        },
                    });
                }

                if (result.error) {
                    toast.error(result.error.error);
                    return false;
                }

                toast.success('Установка сохранена');
                navigate('/units');
            } catch (err) {
                console.error(err);
                toast.error('Ошибка сервера');
            }
            return false;
        }
    });
    const handleParameterChange = (event) => {
        setSelectedParameter(event.target.value);
    };


    const currentTab = useSelector((state) => state.settings.currentUnitTab);

    const {data: unitTypes = [], isFetchingUnitTypes} = useGetUnitTypesQuery();

    useEffect(() => {
        if (!id && unitTypes.length) {
            formik.setValues({model_id: unitTypes[0].id});
        }
    }, [unitTypes]);


    const tabs = [
        {
            label: 'Основные параметры',
            value: 'main',
            component:
                <Stack spacing={3}>
                    <TextField
                        error={!!(formik.touched.serial && formik.errors.serial)}
                        fullWidth
                        helperText={formik.touched.serial && formik.errors.serial}
                        label={t('Серийный номер')}
                        name="serial"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        required
                        value={formik.values.serial}
                    />

                    <TextField
                        error={!!(formik.touched.model_id && formik.errors.model_id)}
                        helperText={formik.touched.model_id && formik.errors.model_id}
                        label={t('Тип установки')}
                        name="model_id"
                        onChange={formik.handleChange}
                        select
                        SelectProps={{native: true}}
                        value={formik.values.model_id}
                    >
                        {unitTypes.map((unitType, index) => {
                            return <option
                                key={'unit-type-' + index}
                                value={unitType.id}
                            >
                                {unitType.model}
                            </option>
                        })}
                    </TextField>
                    <TextField
                        error={!!(formik.touched.unit_type_to && formik.errors.unit_type_to)}
                        helperText={formik.touched.unit_type_to && formik.errors.unit_type_to}
                        label={t('Тип для ТО')}
                        name="unit_type_to"
                        onChange={formik.handleChange}
                        select
                        SelectProps={{native: true}}
                        value={formik.values.unit_type_to}
                    >
                        <option key='unitTypeTO1' value='dvs'>Установка с ДВС</option>
                        <option key='unitTypeTO2' value='shpm'>Установка с ШПМ</option>
                        <option key='unitTypeTO3' value='w'>Установка W</option>
                    </TextField>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.emergency_sensors || false}
                                        onChange={(event) => {
                                            formik.setFieldValue('emergency_sensors', event.target.checked);
                                        }}
                                        name="emergency_sensors"
                                    />
                                }
                                label={t('Наличие датчиков аварий')}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.ust_available || false}
                                        onChange={(event) => {
                                            formik.setFieldValue('ust_available', event.target.checked);
                                        }}
                                        name="ust_available"
                                    />
                                }
                                label={t('Поддержка уставок')}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        error={!!(formik.touched.status && formik.errors.status)}
                        helperText={formik.touched.status && formik.errors.status}
                        label={t('Статус')}
                        name="status"
                        onChange={formik.handleChange}
                        select
                        SelectProps={{native: true}}
                        value={formik.values.status ?? 'ok'}
                    >
                        <option value='ok'>В работе</option>
                        <option value='not_available'>Нет сигнала</option>
                        <option value='accident'>Авария</option>
                        <option value='pending'>Не в работе</option>
                    </TextField>
                    <DatePicker
                        label={t('Дата ввода в эксплуатацию')}
                        name="started_at"
                        value={new Date(formik.values.started_at) ?? new Date()}
                        onChange={value => formik.setFieldValue('started_at', value)}
                        format="dd.MM.yyyy"
                        inputVariant="outlined"
                        fullWidth
                        helperText={formik.touched.started_at && formik.errors.started_at}
                    />
                    {/*<TextField*/}
                    {/*    error={!!(formik.touched.description && formik.errors.description)}*/}
                    {/*    fullWidth*/}
                    {/*    multiline*/}
                    {/*    helperText={formik.touched.description && formik.errors.description}*/}
                    {/*    label={t('Комментарий')}*/}
                    {/*    name="description"*/}
                    {/*    onBlur={formik.handleBlur}*/}
                    {/*    onChange={formik.handleChange}*/}
                    {/*    value={formik.values.description ?? ''}*/}
                    {/*/>*/}
                </Stack>,
        },
        {
            label: 'Значение параметров',
            value: 'current',
            component:
                <Grid container spacing={3}>
                    {
                        parameters.filter((el)=>{
                            return (["input", "working", "hours"].indexOf(el.param_group)>-1);
                        }).map((parameter, index) => {
                            const {min, max, units, tag, description} = parameter;
                            return <Grid item xs={12} sm={6} key={index}>
                                    <TextField
                                        type="number"
                                        inputProps={{
                                            min: min,
                                            max: max,
                                        }}
                                        error={!!(formik.touched[tag] && formik.errors[tag])}
                                        fullWidth
                                        helperText={formik.touched[tag] && formik.errors[tag]}
                                        label={description + ` (${units})`}
                                        name={tag}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values[tag]}
                                    />
                            </Grid>
                        })
                    }
                </Grid>
        },
        {
            label: 'Уставки',
            value: 'alarms',
            component:
                <Stack spacing={3}>
                    <Table>
                        <TableBody>
                            {
                                parameters.filter((el)=>{
                                    return (["ust"].indexOf(el.param_group)>-1);
                                }).map((parameter, index) => {
                                    return <TableRow key={index}>
                                        <TableCell>
                                            {parameter.tag}
                                        </TableCell>
                                        <TableCell>
                                            {parameter.description}
                                        </TableCell>
                                        <TableCell>
                                            {parameter.type === 'float' ? (
                                                <TextField
                                                    label="Значение"
                                                    value={formik.values[parameter.tag]}
                                                    onChange={event => formik.setFieldValue(`${parameter.tag}`, event.target.value)}
                                                    fullWidth
                                                />
                                            ) : (
                                                <Switch
                                                    checked={formik.values[parameter.tag] ? true : false}
                                                    onChange={event => formik.setFieldValue(`${parameter.tag}`, event.target.checked)}
                                                />
                                            )}
                                        </TableCell>
                                        {/*<TableCell>
                                            <Button
                                                color="error"
                                                onClick={() => {
                                                    const newParameters = [...formik.values.ust].filter((_, i) => i !== index);
                                                    formik.setFieldValue(`ust`, newParameters)
                                                }}>
                                                Удалить
                                            </Button>
                                        </TableCell>*/}
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                    {/*<Button
                        onClick={() => {
                            formik.setFieldValue('ust', [...formik.values.ust, {
                                tag: '',
                                name: '',
                                type: 'numeric',
                                value: ''
                            }])
                        }}
                    >
                        Добавить уставку
                    </Button>*/}
                </Stack>
        }
        ,
        {
            label: 'Аварии',
            value: 'sensors',
            component:
                <Stack spacing={3}>
                    <Table>
                        <TableBody>

                            {
                                parameters.filter((el)=>{
                                    return (["accident"].indexOf(el.param_group)>-1);
                                }).map((parameter, index) => {
                                    let value = formik.values[parameter.tag]
                                    if (value) {
                                       return <TableRow key={index}>
                                           <TableCell>
                                               <TextField
                                                   label={t('Дата аварии')}
                                                   name={'date'}
                                                   value={"2023-07-03 21:22:23"}
                                                   onChange={value => formik.setFieldValue(`accidents[${index}].date`, value)}
                                                   inputVariant="outlined"
                                                   fullWidth
                                               />
                                           </TableCell>
                                           <TableCell>
                                               <TextField
                                                   label="Причина аварии"
                                                   value={parameter.description}
                                                   onChange={event => formik.setFieldValue(`accidents[${index}].description`, event.target.value)}
                                                   fullWidth
                                               />
                                           </TableCell>
                                           <TableCell>
                                               <FormControl fullWidth>
                                                   <InputLabel>Статус</InputLabel>
                                                   <Select
                                                       native
                                                       value={'active'}
                                                       onChange={event => formik.setFieldValue(`accidents[${index}].type`, event.target.value)}
                                                       fullWidth
                                                   >
                                                       <option value="active" selected>Активна</option>
                                                       <option value="completed">Решена</option>
                                                   </Select>
                                               </FormControl>
                                           </TableCell>

                                           <TableCell>
                                               <Button
                                                   color="error" onClick={() => {
                                                   const newParameters = [...formik.values.accidents].filter((_, i) => i !== index);
                                                   formik.setFieldValue(`accidents`, newParameters)
                                               }}>
                                                   Удалить
                                               </Button>
                                           </TableCell>
                                       </TableRow>
                                    }
                                    return null;
                                })
                            }
                            {/*
                            {formik.values?.accidents?.map((parameter, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <DatePicker
                                            label={t('Дата аварии')}
                                            name={parameter.date}
                                            value={new Date(parameter.date) ?? new Date()}
                                            onChange={value => formik.setFieldValue(`accidents[${index}].date`, value)}
                                            format="dd.MM.yyyy"
                                            inputVariant="outlined"
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            label="Причина аварии"
                                            value={parameter.description}
                                            onChange={event => formik.setFieldValue(`accidents[${index}].description`, event.target.value)}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormControl fullWidth>
                                            <InputLabel>Статус</InputLabel>
                                            <Select
                                                native
                                                value={parameter.type}
                                                onChange={event => formik.setFieldValue(`accidents[${index}].type`, event.target.value)}
                                                fullWidth
                                            >
                                                <option value="active">Активна</option>
                                                <option value="completed">Решена</option>
                                            </Select>
                                        </FormControl>
                                    </TableCell>

                                    <TableCell>
                                        <Button
                                            color="error" onClick={() => {
                                            const newParameters = [...formik.values.accidents].filter((_, i) => i !== index);
                                            formik.setFieldValue(`accidents`, newParameters)
                                        }}>
                                            Удалить
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            */}
                        </TableBody>
                    </Table>
                    {/*<Button*/}
                    {/*    onClick={() => {*/}
                    {/*        formik.setFieldValue('accidents', [...formik.values.accidents, {*/}
                    {/*            date: new Date(),*/}
                    {/*            type: 'active',*/}
                    {/*            description: ''*/}
                    {/*        }])*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Добавить аварию*/}
                    {/*</Button>*/}
                </Stack>
        }
    ];

    tabs.push({
            label: 'Документы',
            value: 'documents',
            component: (
                        <Stack spacing={3}>
                            <FileDropzone
                                accept={{ 'application/pdf': [] }}
                                maxFiles={10}
                                setFiles={setUploadedFiles}
                                setFileIds={setUploadedFileIds}
                                files={uploadedFiles}
                                fileIds={uploadedFileIds}
                                fetchedFiles={formik.values.files??[]}
                                onUploadComplete={(newFiles) => {
                                    formik.setFieldValue(`files`, newFiles)
                                }}
                            />
                            {formik.values.files?.length > 0 && (
                                <FileList
                                    files={formik.values.files??[]}
                                    fileIds={uploadedFileIds}
                                    setFiles={(newFiles) => {
                                        formik.setFieldValue(`files`, newFiles)
                                    }}
                                />
                            )}
                        </Stack>
                    ),
    });

    tabs.push({
        label: 'Графики',
        value: 'charts',
        component:
            <Stack spacing={3}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Выберите параметр</InputLabel>
                    <Select
                        value={selectedParameter}
                        onChange={handleParameterChange}
                        label="Выберите параметр"
                    >
                        {parameters.map((param, index) => (
                            <MenuItem key={index} value={param.tag}>
                                {param.description}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <LineChart
                    width={document.body.clientWidth / 2}
                    height={300}
                    data={fetchedChartData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey="time_stamp" />
                    <YAxis />
                    <Tooltip />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Line type="monotone" dataKey="value" stroke="#ff7300" yAxisId={0} />
                </LineChart>
            </Stack>
    });

    return <PageLayout
        title={id ? "Редактирование установки " + formik.values.serial : "Создание установки"}
    >
            <Card>
                <CardContent sx={{p: 2}}>
                    <TabsLayout tabs={tabs} currentTab={currentTab} setCurrentTab={setUnitTab}/>
                    <Stack
                        direction={{
                            xs: 'column',
                            sm: 'row'
                        }}
                        flexWrap="wrap"
                        spacing={3}
                        sx={{p: 3}}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => navigate(-1)}
                        >
                            {t('Отмена')}
                        </Button>
                        <Box sx={{flex: 1}}></Box>
                        {id && <Button
                            disabled={formik.isSubmitting}
                            variant="outlined"
                            onClick={() => setOpenDeleteDialog(true)}
                            color="error"
                        >
                            {t('Удалить')}
                        </Button>}
                        <Button
                            disabled={formik.isSubmitting}
                            onClick={() => formik.handleSubmit()}
                            type="submit"
                            variant="contained"
                        >
                            {t('Сохранить')}
                        </Button>
                    </Stack>

                </CardContent>
            </Card>
        <ConfirmationDialog
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
            onConfirm={async () => {
                setOpenDeleteDialog(false);
                const result = await deleteUnit(id);
                if (result.error) {
                    toast.error(result.error.error);
                    return false;
                }
                toast.success('Установка удалена');
                navigate(-1);
            }}
        />
    </PageLayout>

        ;
};

export default UnitEdit;