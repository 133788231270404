import { createAsyncThunk } from '@reduxjs/toolkit';
import baseApi from './api';

export const user = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        loginUser : builder.query({
            query: (credentials ) => ({
                url: '/login',
                method: 'POST',
                body: {
                    email: credentials.email,
                    password: credentials.password
                },
            }),
            invalidatesTags: ['user'],
            transformResponse: (response) => {
                return response.data;
            }
        }),
        getCurrentUser : builder.query({
            query: () => '/me',
            providesTags: ['user'],
            transformResponse: (response) => {
                return response.data;
            }
        }),
    }),
})

export const { useGetCurrentUserQuery, useLoginUserQuery } = user;

export const loginUser = createAsyncThunk(
    'user/login',
    async ({ email, password }, thunkAPI) => {
        try {
            const data = await thunkAPI.dispatch(user.endpoints.loginUser.initiate({ email, password }));
            if (data.error) {
                return thunkAPI.rejectWithValue(data.error.data.errorMessage);
            } else {
                window.localStorage.setItem('user.jwt', data.data?.jwt_token);
                return data.data?.jwt_token;
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)