import * as Yup from 'yup';
import i18next from 'i18next';
const t = i18next.t;
export default Yup.object({
    email: Yup
        .string()
        .email(t('Неверный адрес email'))
        .max(255)
        .required(t('Email обязателен для заполнения')),
    password: Yup
        .string()
        .min(4)
        .max(255)
        .required(t('Пароль обязателен для заполнения'))
});
