import {createSlice} from "@reduxjs/toolkit";
export default createSlice({
    name: 'drawer',
    initialState: {
        isOpen: window.localStorage.getItem('drawer.isOpen')?.toLowerCase() === 'true' || false,
    },
    reducers: {
        toggleDrawer: (state) => {
            state.isOpen = !state.isOpen;
            window.localStorage.setItem('drawer.isOpen', state.isOpen);
        },
    },
});