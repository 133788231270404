import React from 'react';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import PageLayout from './../../components/layouts/PageLayout';
import {useSelector} from "react-redux";
function Home() {

    const selectedOrganization = useSelector((state) => state.organization.selected);

    return <PageLayout title={"Выберите организацию"}>
            <div></div>
        </PageLayout>
    ;
}

export default Home;