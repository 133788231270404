import {useCallback, useState} from 'react';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import ChevronRightIcon from '@untitled-ui/icons-react/build/esm/ChevronRight';
import {Box, ButtonBase, Collapse, SvgIcon} from '@mui/material';
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {toggleDrawer} from '../../../store';

const normalizePath = (path) => (path.endsWith('/') ? path.slice(0, -1) : path);
const SideNavLeaf = (props) => {
    const dispatch = useDispatch();

    const handleToggleDrawer = () => {
        dispatch(toggleDrawer());
    };
    const location = useLocation();
    const {children, depth = 0, disabled, icon, label, open: openProp, path, title} = props;

    const active = (normalizePath(location.pathname) === normalizePath(path))

    return (
        <ButtonBase
            component={Link}
            to={path}
            onClick={handleToggleDrawer}
            sx={{
                alignItems: 'center',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                pl: 16 + 16 * depth + `px`,
                pr: '16px',
                py: '6px',
                textAlign: 'left',
                width: '100%',
                ...(active && {
                    ...(depth === 0 && {
                        backgroundColor: 'var(--nav-item-active-bg)'
                    })
                }),
                '&:hover': {
                    backgroundColor: 'var(--nav-item-hover-bg)'
                }
            }}>
            <Box
                component="span"
                sx={{
                    color: 'var(--nav-item-color)',
                    fontFamily: (theme) => theme.typography.fontFamily,
                    fontSize: depth > 0 ? 13 : 14,
                    fontWeight: depth > 0 ? 500 : 600,
                    lineHeight: '24px',
                    whiteSpace: 'nowrap',
                    ...(active && {
                        color: 'var(--nav-item-active-color)'
                    }),
                    ...(disabled && {
                        color: 'var(--nav-item-disabled-color)'
                    })
                }}
            >
                {title}
            </Box>
            {label && (
                <Box
                    component="span"
                    sx={{ml: 2}}
                >
                    {label}
                </Box>
            )}
        </ButtonBase>
    )
}
export const SideNavItem = (props) => {
    const {children, depth = 0, disabled, icon, label, open: openProp, path, title} = props;
    //const active = normalizePath(path).length ? normalizePath(location.pathname).indexOf(normalizePath(path)) > -1 : (normalizePath(location.pathname) === normalizePath(path));

    const [open, setOpen] = useState(!!openProp);

    const handleToggle = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, []);

    const offset = depth === 0 ? 0 : (depth - 1) * 16;
    if (children) {

        return (
            <li>
                <ButtonBase
                    disabled={disabled}
                    onClick={handleToggle}
                    sx={{
                        alignItems: 'center',
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        pl: `${16 + offset}px`,
                        pr: '16px',
                        py: '6px',
                        textAlign: 'left',
                        width: '100%',
                        /*...(active && {
                            ...(depth === 0 && {
                                backgroundColor: 'var(--nav-item-active-bg)'
                            })
                        }),*/
                        '&:hover': {
                            backgroundColor: 'var(--nav-item-hover-bg)'
                        }
                    }}
                >
                    <Box
                        component="span"
                        sx={{
                            color: 'var(--nav-item-color)',
                            flexGrow: 1,
                            fontFamily: (theme) => theme.typography.fontFamily,
                            fontSize: depth > 0 ? 13 : 14,
                            fontWeight: depth > 0 ? 500 : 600,
                            lineHeight: '24px',
                            whiteSpace: 'nowrap',
                            /*...(active && {
                                color: 'var(--nav-item-active-color)'
                            }),*/
                            ...(disabled && {
                                color: 'var(--nav-item-disabled-color)'
                            })
                        }}
                    >
                        {title}
                    </Box>
                    <SvgIcon
                        sx={{
                            color: 'var(--nav-item-chevron-color)',
                            fontSize: 16,
                            ml: 2
                        }}
                    >
                        {open ? <ChevronDownIcon/> : <ChevronRightIcon/>}
                    </SvgIcon>
                </ButtonBase>
                <Collapse
                    in={open}
                    sx={{mt: 0.5}}
                >
                    {children.map((el, key) => <SideNavLeaf key={key}
                                                            title={el.title}
                                                            path={el.path}
                                                            depth={el.depth}
                    />)}
                </Collapse>
            </li>
        )
    } else {
        return <SideNavLeaf title={title} path={path} depth={depth}/>
    }
};

/*
SideNavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
};
*/