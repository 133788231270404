import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, Tab, Tabs, Divider, Stack, LinearProgress} from '@mui/material';
import PageLayout from './../../components/layouts/PageLayout';
import PlainTable from "../../components/common/PlainTable";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import { generateEndpoints } from '../../services/api';
const { useGetUnitsQuery } = generateEndpoints('unit');
const { useGetUnitTypesQuery } = generateEndpoints('unit-type');
import {useSelector} from "react-redux";
import renderStatus from "../../components/features/Units/renderStatus";
function Units() {
    const {t} = useTranslation();
    const selectedOrganization = useSelector((state) => state.organization.selected);

    const { data: units = [], isFetching } = useGetUnitsQuery(selectedOrganization?.id || null);
    const { data: unitTypes = [], isFetchingUnitTypes } = useGetUnitTypesQuery();

    const columns = [
        {
            title: t('Серийный номер'),
            dataKey: "serial",
        },
        {
            title: t('Статус'),
            dataKey: "status",
            render: (unit) => {
                return <div>{renderStatus(unit.status)}</div>
            },
        },
        {
            title: t('Тип установки'),
            dataKey: "model_id",
            render: (unit) => {
                return <div>{unitTypes?.find(unitType => unitType.id == unit.model_id)?.model}</div>
            },
        },
        {
            title: t('Действия'),
            dataKey: "",
            render: (unit) => (
                <Link to={`/units/${unit.id}`}><Button variant={"outlined"}>{t('Редактировать')}</Button></Link>
            ),
        },
    ];
    return <PageLayout
        title={"Установки"}
        // actions={[{label: "Добавить установку", href: "/units/add"}]}
    >
        <Card><CardContent>
            {isFetching?<LinearProgress />:
                <PlainTable columns={columns} data={units} />
            }
        </CardContent>
        </Card>
    </PageLayout>
}

export default Units;