import React, { useState, useEffect } from "react";
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    TableFooter,
    InputAdornment,
    OutlinedInput,
    SvgIcon,
    TableContainer,
    Box
} from '@mui/material';
import {useTranslation} from "react-i18next";

const PlainTable = ({ columns, data, pagination }) => {
    const {t} = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSortColumn = (column) => {
        const isAsc = sortColumn === column && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortColumn(column);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const sortedData = [...data].filter(row => {
        return columns.some(column => {
            return row[column.dataKey]?.toString().toLowerCase().includes(searchTerm.toLowerCase());
        });
    }).sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        if (a[sortColumn] > b[sortColumn]) {
            return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const filteredData = sortedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);


    return (
        <>
            <TableContainer sx={{minHeight:'40vh'}}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.dataKey} sx={{width: column.width}}>
                                    {
                                        column.dataKey?
                                            <TableSortLabel
                                                active={sortColumn === column.dataKey}
                                                direction={sortColumn === column.dataKey ? sortDirection : 'asc'}
                                                onClick={() => handleSortColumn(column.dataKey)}
                                            >
                                                {column.title}
                                            </TableSortLabel>
                                            :
                                            column.title
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((row) => (
                            <TableRow hover  key={row.id}>
                                {columns.map((column) => (
                                    <TableCell key={column.dataKey}>
                                        {column.render ? (
                                            column.render(row)
                                        ) : (
                                            column.type === 'file' ? (
                                                    row.files && row.files.map((file, index) => {
                                                    if (file.mime === 'image/png' || file.mime === 'image/jpeg'){
                                                        return <Box
                                                            key={index}
                                                            component="img"
                                                            src={file.src}
                                                            sx={{maxWidth:'100px', height:'auto'}}
                                                        />
                                                    }   else {
                                                        return <Box
                                                            key={index}
                                                            component="a"
                                                            href={file.src}
                                                            target="_blank"
                                                        >{file.filename}</Box>
                                                    }
                                                })
                                            ) :
                                            row[column.dataKey]
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


    <Table sx={{width:'100%'}}>
        <TableBody>
        <TableRow>
            <TableCell sx={{p:0, paddingBottom: 1, minWidth:100}}>
                <OutlinedInput
                    onChange={handleSearchTermChange}
                    value={searchTerm}
                    placeholder={t("Поиск")}
                    startAdornment={(
                        <InputAdornment position="start">
                            <SvgIcon>
                                <SearchMdIcon />
                            </SvgIcon>
                        </InputAdornment>
                    )}
                />
            </TableCell>
            <TablePagination
                count={data.length}
                rowsPerPageOptions={[10, 20, 30]}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage={t("Строк на странице")}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t("из")} ${count}`}
            />
        </TableRow>
        </TableBody>
    </Table>
    </>
    );
};

export default PlainTable;