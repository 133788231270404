import {createSlice} from "@reduxjs/toolkit";
export default createSlice({
    name: "settings",
    initialState: {
        currentTab: 'organizations',
        currentUnitTab: 'main',
    },
    reducers: {
        setSettingsTab: (state, action) => {
            state.currentTab = action.payload;
        },
        setUnitTab: (state, action) => {
            state.currentUnitTab = action.payload;
        },
    },
});