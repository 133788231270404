import React, { useState } from 'react';
import {
    Button,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { generateEndpoints } from '../../services/api';
const {
    useGetParametersQuery,
    useUpdateParameterMutation,
    useCreateParameterMutation,
    useDeleteParameterMutation } = generateEndpoints('parameter');

const renderVisualType = (visual_type) => {
    switch(visual_type) {
        case 'text':
            return 'Текст';
        case 'bar':
            return 'Линейный индикатор';
        case 'columns':
            return 'Колонки';
        default:
            return '';
    }
}
const renderGroup = (param_group) => {
    switch(param_group) {
        case 'input':
            return 'Вводимый оператором';
        case 'working':
            return 'Рабочий параметр';
        case 'ust':
            return 'Уставка';
        case 'serial':
            return 'Серийный номер';
        case 'hours':
            return 'Моточасы';
        case 'accident':
            return 'Аварийный сигнал';
        case 'accident_param':
            return 'Аварийная уставка';
        case 'stops':
            return 'Отключение датчиков';
        default:
            return param_group;
    }
}
const ParamSettings = () => {
    const initialFormState = { id: '', tag: '', description: '', units: '', param_group: '', visual_type: '', min: '', max: '' };
    const { data: parameters = []} = useGetParametersQuery();
    const [updateParameter] = useUpdateParameterMutation();
    const [editing, setEditing] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [parameter, setParameter] = useState(initialFormState);
    const [open, setOpen] = useState(false);

    const [errors, setErrors] = useState({});

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setParameter({ ...parameter, [name]: value });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isValid()) {
            if (parameter.visual_type === 'bar') {
                parameter.min = parseFloat(parameter.min);
                parameter.max = parseFloat(parameter.max);
            }
            if(editing) {
                //parameters[currentIndex] = parameter;
                let result = await updateParameter({
                    id: parameter.id,
                    data: parameter
                });
                setEditing(false);
            } else {
                //setParameters([...parameters, parameter]);
            }
            handleClose();
        }
    }
    const isValid = () => {
        let formErrors = {};
        let valid = true;
        if (parameter.tag?.trim() === '') {
            valid = false;
            formErrors = {...formErrors, index: 'Индекс не может быть пустым'};
        }
        if (parameter.description?.trim() === '') {
            valid = false;
            formErrors = {...formErrors, units: 'Название не может быть пустым'};
        }
        if (!parameter.param_group || parameter.param_group?.trim() === '') {
            valid = false;
            formErrors = {...formErrors, param_group: 'Группа не может быть пустой'};
        }
        if (!parameter.visual_type || parameter.visual_type?.trim() === '') {
            valid = false;
            formErrors = {...formErrors, visual_type: 'Тип не может быть пустым'};
        }
        if (parameter.visual_type === 'Линейный индикатор') {
            if (parameter.min?.trim() === '') {
                valid = false;
                formErrors = {...formErrors, min: 'Мин. значение не может быть пустым'};
            }
            if (parameter.max?.trim() === '') {
                valid = false;
                formErrors = {...formErrors, max: 'Макс. значение не может быть пустым'};
            }
        }
        setErrors(formErrors);
        return valid;
    }
    const editRow = (index) => {
        setEditing(true);
        setCurrentIndex(index);
        setParameter(parameters[index]);
        handleOpen();
    }

    const deleteRow = (index) => {
        if(editing && index === currentIndex) {
            setEditing(false);
        }
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setParameter(initialFormState);
        setEditing(false);
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen}>Добавить параметр</Button>

            <Dialog  open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{editing ? 'Редактирование параметра' : 'Добавление параметра'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <TextField
                                error={!!errors.tag}
                                helperText={errors.tag ? errors.tag : ''}
                                name="tag" label="Тег" value={parameter.tag} onChange={handleInputChange} />
                            <TextField
                                error={!!errors.description}
                                helperText={errors.description ? errors.description : ''}
                                name="description" label="Название" value={parameter.description} onChange={handleInputChange} />
                            <TextField
                                error={!!errors.units}
                                helperText={errors.units ? errors.units : ''}
                                name="units" label="Ед.изм." value={parameter.units} onChange={handleInputChange} />
                            <FormControl style={{minWidth: '120px'}}>
                                <InputLabel>Группа</InputLabel>
                                <Select
                                    name="param_group"
                                    value={parameter.param_group}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="input">Вводимый оператором</MenuItem>
                                    <MenuItem value="working">Рабочий параметр</MenuItem>
                                    <MenuItem value="serial">Серийный номер</MenuItem>
                                    <MenuItem value="hours">Моточасы</MenuItem>
                                    <MenuItem value="ust">Уставка</MenuItem>
                                    <MenuItem value="accident">Аварийный сигнал</MenuItem>
                                    <MenuItem value="accident_param">Аварийная уставка</MenuItem>
                                    <MenuItem value="stops">Отключение датчиков</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl style={{minWidth: '120px'}}>
                                <InputLabel>Тип</InputLabel>
                                <Select
                                    name="visual_type"
                                    value={parameter.visual_type}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="text">Текст</MenuItem>
                                    <MenuItem value="bar">Линейный индикатор</MenuItem>
                                    <MenuItem value="columns">Колонки</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                type="number"
                                name="sort" label="Сортировка" value={parameter.sort} onChange={handleInputChange} />
                            {parameter.visual_type === 'bar' && (
                                <>
                                    <TextField
                                        error={!!errors.min}
                                        helperText={errors.min ? errors.min : ''}
                                        name="min" label="Мин." value={parameter.min} onChange={handleInputChange} />
                                    <TextField
                                        error={!!errors.max}
                                        helperText={errors.max ? errors.max : ''}
                                        name="max" label="Макс." value={parameter.max} onChange={handleInputChange} />
                                </>
                            )}
                        </Stack>
                    </form>
                    <div>{errors?
                        Object.keys(errors).map((key, index) => (
                            <div key={index}>{errors[key]}</div>
                        ))
                    : null
                    }</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        {editing ? 'Сохранить' : 'Добавить'}
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper} style={{marginTop: '50px'}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Тег</TableCell>
                            <TableCell>Название</TableCell>
                            <TableCell>Ед. изм.</TableCell>
                            <TableCell>Группа</TableCell>
                            <TableCell>Тип представления</TableCell>
                            <TableCell>Мин</TableCell>
                            <TableCell>Макс</TableCell>
                            <TableCell>Сортировка</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {parameters.map((parameter, index) => (
                            <TableRow key={index}>
                                <TableCell>{parameter.id}</TableCell>
                                <TableCell>{parameter.tag}</TableCell>
                                <TableCell>{parameter.description}</TableCell>
                                <TableCell>{parameter.units}</TableCell>
                                <TableCell>{renderGroup(parameter.param_group)}</TableCell>
                                <TableCell>{renderVisualType(parameter.visual_type)}</TableCell>
                                <TableCell>{parameter.min}</TableCell>
                                <TableCell>{parameter.max}</TableCell>
                                <TableCell>{parameter.sort}</TableCell>
                                <TableCell>
                                    <Stack direction="row" spacing={1}>
                                    <IconButton color="primary" onClick={() => editRow(index)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={() => deleteRow(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ParamSettings;
