import {createSlice} from "@reduxjs/toolkit";
export default createSlice({
    name: "settings",
    initialState: {
        currentUser: false,
        jwt: window.localStorage.getItem('user.jwt') || null,
    },
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        setJwt: (state, action) => {
            state.jwt = action.payload;
        },
    },
});