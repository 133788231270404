import React, {useEffect, useState} from 'react';
import {
    LinearProgress,
    Stack,
    Button,
    Card,
    CardContent,
    TextField,
    Box
} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import toast from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PageLayout from "../../components/layouts/PageLayout";
import { generateEndpoints } from '../../services/api';
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
const { useGetUnitTypeByIdQuery, useUpdateUnitTypeMutation, useCreateUnitTypeMutation, useDeleteUnitTypeMutation  } = generateEndpoints('unit-type');

const UnitTypeEdit = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const { data: fetchedUnitType = {}, isFetching } = useGetUnitTypeByIdQuery(id);
    const [updateUnitType, { isLoading }] = useUpdateUnitTypeMutation();
    const [createUnitType] = useCreateUnitTypeMutation();
    const [deleteUnitType] = useDeleteUnitTypeMutation();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            model: fetchedUnitType.model || '',
            submit: null
        },
        validationSchema: Yup.object({
            model: Yup
                .string()
                .max(255)
                .required('Название обязательно для заполнения')
        }),
        onSubmit: async (values, helpers) => {
            try {
                let result = null;
                if (id) {
                    result = await updateUnitType({ id, data: { model: values.model } });
                } else {
                    result = await createUnitType({ data: { model: values.model } });
                }
                if (result.error){
                    toast.error(result.error.error);
                    return false;
                }
                toast.success('Тип установки сохранен');
                navigate('/settings');
            } catch (err) {
                console.error(err);
                toast.error('Ошибка сервера');
            }
            return false;
        }
    });

    useEffect(() => {
        if (fetchedUnitType.model) {
            formik.setValues({model: fetchedUnitType.model});
        }
    }, [fetchedUnitType]);

    return <PageLayout
        title={id?"Редактирование типа установки "+(fetchedUnitType.model??''):"Создание типа установки"}
    >
        {isFetching?<LinearProgress />:
            <form
                onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent sx={{ p: 2 }}>
                        <TextField
                            error={!!(formik.touched.model && formik.errors.model)}
                            fullWidth
                            helperText={formik.touched.model && formik.errors.model}
                            label={t('Название')}
                            name="model"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required
                            value={formik.values.model}
                        />
                    </CardContent>
                    <Stack
                        direction={{
                            xs: 'column',
                            sm: 'row'
                        }}
                        flexWrap="wrap"
                        spacing={3}
                        sx={{ p: 3 }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => navigate(-1)}
                        >
                            {t('Отмена')}
                        </Button>
                        <Box sx={{ flex: 1 }}></Box>
                        {id ? <Button
                            disabled={formik.isSubmitting}
                            variant="outlined"
                            onClick={() => setOpenDeleteDialog(true)}
                            color="error"
                        >
                            {t('Удалить')}
                        </Button>: null}
                        <Button
                            disabled={formik.isSubmitting}
                            type="submit"
                            variant="contained"
                        >
                            {t('Сохранить')}
                        </Button>
                    </Stack>
                </Card>
                <ConfirmationDialog
                    open={openDeleteDialog}
                    setOpen={setOpenDeleteDialog}
                    onConfirm={async () => {
                        setOpenDeleteDialog(false);
                        const result = await deleteUnitType( id );
                        if (result.error){
                            toast.error(result.error.error);
                            return false;
                        }
                        toast.success('Тип установки удален');
                        navigate('/settings');
                    }}
                />
            </form>
        }
    </PageLayout>

        ;
};

export default UnitTypeEdit;