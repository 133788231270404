import React from 'react';
import {Container, Box, LinearProgress, Stack, TableBody, TableRow, TableCell, Typography, Button} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import PlainTable from "../../components/common/PlainTable";
import {useTranslation} from "react-i18next";

import { generateEndpoints } from '../../services/api';
const { useGetBannersQuery } = generateEndpoints('banner');

const UnitTypesList = () => {
    const {t} = useTranslation();
    const { data: banners = [], isFetching } = useGetBannersQuery();

    const columns = [
        {
            title: t('Баннер'),
            width: '100%',
            dataKey: "name",
        },
        {
            title: t('Изображение'),
            width: '100%',
            type: 'file',
            dataKey: "src",
        },
        {
            title: t('Действия'),
            dataKey: "",
            render: (banner) => (
                <Link to={`/settings/banners/${banner.id}`}><Button variant={"outlined"}>{t('Редактировать')}</Button></Link>
            ),
        },
    ];

    return <>
        {isFetching?<LinearProgress />:
            <Box>
                <PlainTable columns={columns} data={banners} />
            </Box>
        }
    </>
    ;
};

export default UnitTypesList;