import React from 'react';
import {Button, Stack} from '@mui/material';
import toast from 'react-hot-toast';

const IntegrationSettings = () => {

    const handleApiCall = async (endpoint, method = 'GET') => {
        try {
            const response = await fetch(endpoint, { method });

            if (!response.ok && response.status !== 200) {
                toast.error(`Ошибка сервера (${response.status})`);
                return;
            }

            const data = await response.json();

            if (!response.ok) {
                toast.error(`Ошибка при выполнении операции`);
                return;
            }

            toast.success('Операция выполнена успешно');
            console.log(data);
        } catch (error) {
            toast.error('Ошибка при выполнении операции');
            console.error(`Error fetching data from ${endpoint}:`, error);
        }
    };

    return (
        <>
            <Stack spacing={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleApiCall('/api/v1/integration/clear')}
                >
                    Очистить БД
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleApiCall('/api/v1/integration/initial')}
                >
                    Установки и компании
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleApiCall('/api/v1/integration/data')}
                >
                    Параметры установок
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleApiCall('/api/v1/integration/site')}
                >
                    Блоги и новости
                </Button>
            </Stack>

        </>
    );
}

export default IntegrationSettings;
