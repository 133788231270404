import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import { Box, IconButton, Stack, SvgIcon, useMediaQuery } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { AccountButton } from './AccountButton';
import { NotificationsButton } from './NotificationsButton';

import {useDispatch} from 'react-redux';
import {toggleDrawer} from '../../../store';

const TOP_NAV_HEIGHT = 64;


export const Topbar = (props) => {
    const dispatch = useDispatch();

    const handleToggleDrawer = () => {
        dispatch(toggleDrawer());
    };
    const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    return (
        <Box
            component="header"
            sx={{
                backdropFilter: 'blur(6px)',
                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
                position: 'sticky',
                left: 0,
                top: 0,
                width: '100%',
                zIndex: (theme) => theme.zIndex.appBar
            }}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    minHeight: TOP_NAV_HEIGHT,
                    px: 2
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    {!smUp && (
                        <IconButton onClick={handleToggleDrawer}>
                            <SvgIcon>
                                <Menu01Icon />
                            </SvgIcon>
                        </IconButton>
                    )}
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    <NotificationsButton />
                    <AccountButton />
                </Stack>
            </Stack>
        </Box>
    );
};

export default Topbar;