import React, {useEffect, useState} from 'react';
import {
    LinearProgress,
    Stack,
    Button,
    Card,
    CardContent,
    TextField, Box
} from '@mui/material';
import { Switch, FormControlLabel, List, ListItem, ListItemText } from '@mui/material';

import {useNavigate, useParams} from "react-router-dom";
import toast from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PageLayout from "../../components/layouts/PageLayout";
import { generateEndpoints } from '../../services/api';
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import {useSelector} from "react-redux";
const { useGetUserByIdQuery, useUpdateUserMutation, useCreateUserMutation, useDeleteUserMutation  } = generateEndpoints('user');
import { setSelectedOrganization } from "../../store";

const NotificationsSection = () => {
    return (
        <Card>
            <CardContent>
                <h3>Уведомления</h3>
                <FormControlLabel
                    control={<Switch />}
                    label="Уведомления о введении дополнительной установки"
                />
                <FormControlLabel
                    control={<Switch />}
                    label="Оповещения, новости"
                />
                <FormControlLabel
                    control={<Switch />}
                    label="Аварии"
                />
                <h4>Список последних уведомлений</h4>
                <List>
                    <ListItem>
                        <ListItemText primary={new Date().toLocaleDateString() + ": Добавлена установка 2209008"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={new Date().toLocaleDateString() + ": Добавлена установка 2209007"} />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
};


const UserEdit = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const { data: fetchedUser = {}, isFetching = false } = id ? useGetUserByIdQuery(id) : { data: {} };
    const [updateUser, { isLoading }] = useUpdateUserMutation();
    const [createUser] = useCreateUserMutation();
    const [deleteUser] = useDeleteUserMutation();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const selectedOrganization = useSelector((state) => state.organization.selected);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: fetchedUser.name || '',
            submit: null
        },
        validationSchema: Yup.object({
            login: Yup
                .string()
                .max(255)
                .required('Логин обязателен для заполнения'),
            name: Yup
                .string()
                .nullable()
                .max(255),
            email: Yup
                .string()
                .nullable()
                .email('Неверный формат email')
                .max(255),
            phone: Yup
                .string()
                .nullable()
                .min(9)
                .max(255),
            job_title: Yup
                .string()
                .nullable()
                .max(255),
            password: Yup
                .string()
                .min(6, 'Пароль должен быть не менее 6 символов')
                .max(255)

        }),
        onSubmit: async (values, helpers) => {
            try {
                let result = null;
                if (typeof(id)!='undefined') {
                    result = await updateUser({ id, data: { 
                            name: values.name,
                            organization_id: selectedOrganization.id,
                            login: values.login,
                            email: values.email,
                            phone: values.phone,
                            job_title: values.job_title,
                            password: values.password
                    } });
                } else {
                    result = await createUser({ data: {
                            name: values.name,
                            organization_id: selectedOrganization.id,
                            login: values.login,
                            email: values.email,
                            phone: values.phone,
                            job_title: values.job_title,
                            password: values.password 
                    } });
                }
                if (result.error){
                    toast.error(result.error.error);
                    return false;
                }
                toast.success('Пользователь сохранен');
                navigate('/users');
            } catch (err) {
                console.error(err);
                toast.error('Ошибка сервера');
            }
            return false;
        }
    });
    let fullName = '';
    useEffect(() => {
        if (fetchedUser.name) {
            formik.setValues(fetchedUser);
            fullName = fetchedUser.name;
        }
    }, [fetchedUser]);

    return <PageLayout
        title={id?("Редактирование пользователя №"+fetchedUser.id):"Создание пользователя"}
    >
        <Stack spacing={2}>
        {isFetching?<LinearProgress />:
            <form
                onSubmit={formik.handleSubmit}>
                <Card>
                    <CardContent sx={{ p: 2 }}>
                        <Stack spacing={2} mb={2}>
                        <TextField
                            error={!!(formik.touched.login && formik.errors.login)}
                            fullWidth
                            helperText={formik.touched.login && formik.errors.login}
                            label={t('Логин')}
                            name="login"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required
                            value={formik.values.login}
                        />
                        <TextField
                            error={!!(formik.touched.password && formik.errors.password)}
                            fullWidth
                            helperText={formik.touched.password && formik.errors.password}
                            label={t('Пароль')}
                            required={!id}
                            name="password"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                        <TextField
                            error={!!(formik.touched.name && formik.errors.name)}
                            fullWidth
                            helperText={formik.touched.name && formik.errors.name}
                            label={t('Ф.И.О.')}
                            name="name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required
                            value={formik.values.name}
                        />
                        <TextField
                            error={!!(formik.touched.email && formik.errors.email)}
                            fullWidth
                            helperText={formik.touched.email && formik.errors.email}
                            label={t('Email')}
                            name="email"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        <TextField
                            error={!!(formik.touched.phone && formik.errors.phone)}
                            fullWidth
                            helperText={formik.touched.phone && formik.errors.phone}
                            label={t('Телефон')}
                            name="phone"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                        />
                        <TextField
                            error={!!(formik.touched.job_title && formik.errors.job_title)}
                            fullWidth
                            helperText={formik.touched.job_title && formik.errors.job_title}
                            label={t('Должность')}
                            name="job_title"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.job_title}
                        />
                        </Stack>
                    </CardContent>
                    <Stack
                        direction={{
                            xs: 'column',
                            sm: 'row'
                        }}
                        flexWrap="wrap"
                        spacing={3}
                        sx={{ p: 3 }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => navigate(-1)}
                        >
                            {t('Отмена')}
                        </Button>
                        <Box sx={{ flex: 1 }}></Box>
                        {id ? <Button
                            disabled={formik.isSubmitting}
                            variant="outlined"
                            onClick={() => setOpenDeleteDialog(true)}
                            color="error"
                        >
                            {t('Удалить')}
                        </Button>: null}
                        <Button
                            disabled={formik.isSubmitting}
                            type="submit"
                            variant="contained"
                        >
                            {t('Сохранить')}
                        </Button>
                    </Stack>
                </Card>
                <ConfirmationDialog
                    open={openDeleteDialog}
                    setOpen={setOpenDeleteDialog}
                    onConfirm={async () => {
                        setOpenDeleteDialog(false);
                        const result = await deleteUser( id );
                        if (result.error){
                            toast.error(result.error.error);
                            return false;
                        }
                        toast.success('Пользователь удален');
                        navigate('/users');
                    }}
                />
            </form>
        }
        </Stack>
    </PageLayout>

    ;
};

export default UserEdit;